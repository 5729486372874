<eds-simple-modal
  *ngIf="!page?.isPreview()"
  [modalId]="'signInModal'"
  [showModal]="isVisible"
  [showClose]="showClose"
  [disableCloseOnBackground]="true"
  [hasSideContent]="false"
  (onClose)="closeModal()"
>
  <div modal-body>
    <div #signinModal>
      <div
        *ngIf="languageOptions"
        edsFlexbox
        justifyContent="flex-end"
        edsSpacing="sm"
        class="sign-in-modal__lang-switch"
      >
        <eds-flyout-menu
          [useSmallText]="true"
          [title]="languageOptions.title"
          [items]="languageOptions.items"
          ariaLabelledby="languages"
          [theme]="'basic'"
        ></eds-flyout-menu>
      </div>
      <div edsRow>
        <div edsCol [columnNumbers]="{ s: 4, m: 4, l: 7 }">
          <eds-sign-in
            [hasBorders]="false"
            [hasShadows]="false"
            [hasPadding]="false"
            [signInComponentFields]="signInComponentContent"
            [useSmallText]="useSmallText"
            [isLegacy]="isLegacy"
            [isDisabled]="isSubmitButtonDisabled"
            (onSignIn)="isStaticPassSignIn ? onSubmitExt() : onSubmit($event)"
            [hasMaskedIcon]="true"
            [showMaskedText]="'signin.hide' | translate"
            [hideMaskedText]="'signin.show' | translate"
            [hasHiddenSubmit]="true"
            (fastTrackRegistrationClick)="fastTrackRegistration()"
            (legacyBtnEmiter)="legacyBtnclick($event)"
          ></eds-sign-in>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer class="text-align-right">
    <button
      class="ft__btn sign-in__sign-in-btn spacing ft__btn--emphasis"
      edsButton="primary"
      size="normal"
      [noMargin]="true"
      (click)="onSubmitExt()"
    >
      <span class="ft__btn__txt">{{ "signin.ctaText" | translate }}</span>
    </button>
  </div>
</eds-simple-modal>
