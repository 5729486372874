import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvConfigService } from '@services';
import {
  ApiParameters,
  HttpOptions,
} from '@marketing/subscriptions-manage/subscription-manage.interface';
import { catchError } from 'rxjs/operators';
import { Logger } from '@utils/logger';

/**
 * Logger
 */
const logger = Logger.getLogger('SubscriptionsService');

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(
    public envConfigService: EnvConfigService,
    public http: HttpClient
  ) {}

  /**
   * Returns observable get request
   * @param apiUrl - API URL string
   * @param apiParameters - Object with additional parameters
   */
  public getApiGetRequest$(
    apiUrl: string,
    apiParameters: ApiParameters = {}
  ): Observable<any> {
    let apiUrlWithParams: string = apiUrl;
    if (apiParameters && Object.keys(apiParameters).length > 0) {
      const searchParams: URLSearchParams = new URLSearchParams(apiParameters);
      apiUrlWithParams = apiUrlWithParams + '?' + searchParams;
    }
    return this.http.get(apiUrlWithParams, this.getHttpOptions());
  }

  public getDocumentTypeRequest$(
    apiUrl: string,
    apiParameters: ApiParameters = {}
  ): Observable<any> {
    let apiUrlWithParams: string = apiUrl;
    if (apiParameters && Object.keys(apiParameters).length > 0) {
      const searchParams: URLSearchParams = new URLSearchParams(apiParameters);
      apiUrlWithParams = apiUrlWithParams + '?' + searchParams;
    }
    return this.http.get(apiUrlWithParams, this.getHttpOptions()).pipe(
      catchError((error) => {
        logger.error('Fund Documents API call failed', error);
        return of({ documents: [] });
      })
    );
  }

  /**
   * Returns Observable post request
   * @param apiUrl - API URL string
   * @param requestPayLoad - Post parameters PayLoad
   * @param authToken - Authentication token string
   */
  public getApiPostRequest$(
    apiUrl: string,
    requestPayLoad?: any | null,
    authToken?: string
  ): Observable<any> {
    return this.http.post(
      apiUrl,
      requestPayLoad,
      this.getHttpOptions(authToken)
    );
  }

  /**
   * Returns Observable PUT request
   * @param apiUrl - API URL string
   * @param jsonBody - Put JSON body
   * @param authToken - Authentication token string
   */
  public getApiPutRequest$(
    apiUrl: string,
    jsonBody?: any | null,
    authToken?: string
  ): Observable<any> {
    return this.http.put(apiUrl, jsonBody, this.getHttpOptions(authToken));
  }

  /**
   * Returns headers required for HTTP request
   * @param authToken - Bearer token strung
   */
  private getHttpOptions(authToken?: string): { headers: HttpHeaders } {
    const muleSoftApiId: string = this.envConfigService.getEnvConfig()
      .muleSoftApi.apiID;
    const muleSoftApiSecret: string = this.envConfigService.getEnvConfig()
      .muleSoftApi.apiSecret;
    const muleSoftApiKey: string = this.envConfigService.getEnvConfig()
      .muleSoftApi.apiKey;
    // WDE-3480 - Headers for secure connection.
    // Base on MuleSoft documentation https://docs.mulesoft.com/policies/policies-included-client-id-enforcement
    const headers: HttpOptions = {
      'Content-Type': 'application/json',
      client_id: `${muleSoftApiId}`,
      client_secret: `${muleSoftApiSecret}`,
    };
    if (muleSoftApiKey && muleSoftApiKey !== '') {
      headers['X-FT-API-KEY'] = `${muleSoftApiKey}`;
    }
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
    return {
      headers: new HttpHeaders(headers),
    };
  }
}
