import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppStateService,
  RedirectService,
  SessionService,
  StorageService,
} from '@services';
import { Logger } from '@utils/logger';
import { AlertService } from '@accounts/services/alert.service';
import { DOCUMENT } from '@angular/common';
import { NotificationTypes } from '@accounts/accounts-alerts/accounts-alerts.interface';
import { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';

const logger = Logger.getLogger('Auth0LoginCallbackComponent');

@Component({
  selector: 'ft-auth0-login-callback',
  templateUrl: './auth0-login-callback.component.html',
  styleUrls: ['./auth0-login-callback.component.scss'],
})
export class Auth0LoginCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private appStateService: AppStateService,
    private alertService: AlertService,
    private redirectService: RedirectService,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  ngOnInit(): void {
    const params = new URLSearchParams(this.documentRef.location.search);
    if (params.get('error')) {
      // this.alertService.setAccountsAlert({
      //   verifyEmail: true,
      // });
      this.redirectToPage();
    } else {
      this.sessionService.getAuth0Client()?.then((auth0: Auth0Client) => {
        auth0
          .handleRedirectCallback()
          .then((loginResult: RedirectLoginResult<any>) => {
            this.redirectToPage();
          });
      });
    }
  }

  private redirectToPage() {
    this.storageService.getAuth0CallbackRoute().then((callbackRoute) => {
      const route = callbackRoute || this.appStateService.getHomePageUrl();
      this.redirectService.getRedirect(route).then((redirectedRoute) => {
        if (redirectedRoute) {
          this.router.navigate([redirectedRoute]);
        } else {
          this.router.navigate([route]);
        }
      });
    });
  }
}
