import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InteractiveWidget } from '@marketing/interactive-widgets/interactive-widgets.interface';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import {
  AccessRegisterStatus,
  FastTrackRegistrationService,
  PersonalisationAPIService,
  ProfileService,
  RegisterModalData,
  SignInService,
  ViewModeService,
  WidenService,
} from '@services';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { InteractiveWidgetServiceService } from '@marketing/interactive-widgets/interactive-widget-service.service';
import {
  CtaTheme,
  CtaCustomColors,
  AnalyticsService,
} from '@frk/eds-components';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Logger } from '@utils/logger';
import { LinkCollection, PersonalisationPersonalData } from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import { WINDOW } from '@ng-web-apis/common';
import { LAUNCH_DARKLY_METRICS_NAME } from '@utils/app.constants';

const logger = Logger.getLogger('USServicingLatestDocumentsCardComponent');

interface InteractiveWidgetsParams {
  document?: string;
  theme?: CtaTheme;
  addPadding?: boolean;
  experiment?: string;
  variation?: string;
  customBgColor?: CtaCustomColors;
}
// TODO: Widget Types can be defined in Bloomreach component. For now this component is used only for PCS which is set as default.
// We can add more types when new use cases will be defined
enum WidgetType {
  PCS = 'PCS',
}

@Component({
  selector: 'ft-interactive-widgets',
  templateUrl: './interactive-widgets.component.html',
  styleUrls: ['./interactive-widgets.component.scss'],
})
export class InteractiveWidgetsComponent
  extends AbstractBaseComponent
  implements OnInit, AfterViewInit {
  @Input() myDashboardComponent?: BrComponent;
  @Input() myDashboardPage?: Page;
  @ViewChild('interactiveWidgetsComponent')
  interactiveWidgetsComponent: ElementRef;
  private unSubscribe$: Subject<void> = new Subject<void>();
  public interactiveWidgets: Array<InteractiveWidget>;
  public interactiveWidgetsParams: InteractiveWidgetsParams;
  public isBypassUser: boolean;
  public patToolVariation$: Observable<string>;
  public registerError = false;
  public resendEmail = false;
  public isProfileActivated = false;
  public widgetType: WidgetType = WidgetType.PCS;
  public widgetTypes = WidgetType;
  public isLoggedIn$: Observable<boolean>;
  public widgetsWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    2
  );

  constructor(
    private widenService: WidenService,
    private profileService: ProfileService,
    private interactiveWidgetServiceService: InteractiveWidgetServiceService,
    private viewModeService: ViewModeService,
    private launchDarklyService: LaunchDarklyService,
    private fastTrackRegistrationService: FastTrackRegistrationService,
    private personalisationService: PersonalisationAPIService,
    private changeDetector: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    @Inject(WINDOW) readonly windowRef: Window,
    private signInService: SignInService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.profileService.isLoggedIn$();
    this.interactiveWidgets = this.getInteractiveWidgets() || [];
    this.isBypassUser = this.profileService.isBypass(true);
    // checking if the experiment is present or not
    // then getting the flag from launch darkly to show the variation
    if (this.interactiveWidgetsParams?.experiment) {
      this.initializeLaunchDarkly();
    }
    // listen for register modal after successful registration
    this.fastTrackRegistrationService
      .getRegistrationModalData$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data: RegisterModalData) => {
        logger.debug('After Successful registration:', data);
        if (data) {
          this.resendEmail = data.isRegisterSuccess;
          this.changeDetector.detectChanges();
        }
      });
    // Listen for registration access status
    this.profileService
      .getAccessRegisterStatus$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((accessStatus: AccessRegisterStatus) => {
        logger.debug(accessStatus);
        if (accessStatus === AccessRegisterStatus.ACTIVE) {
          this.isProfileActivated = true;
        }
        if (accessStatus === AccessRegisterStatus.PARTIAL_REG) {
          this.resendEmail = true;
        }
        if (accessStatus === AccessRegisterStatus.ERROR) {
          this.registerError = true;
        }
        this.changeDetector.detectChanges();
      });
    // Listen for window resize
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(() => this.interactiveWidgetsComponent?.nativeElement?.offsetWidth),
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((width: number) => {
        this.widgetsWidth$.next(width);
        logger.debug('Component width:', width);
      });
  }

  ngAfterViewInit() {
    this.widgetsWidth$.next(
      this.interactiveWidgetsComponent?.nativeElement?.offsetWidth
    );
    // Set columns layout for widgets
    this.widgetsWidth$
      .asObservable()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((widgetWidth) => {
        this.interactiveWidgets.forEach((widget: InteractiveWidget) => {
          widget.columnLayout$ = this.getColumnsLayout$(widget, widgetWidth);
          logger.debug('Widget:', widget, widgetWidth);
        });
      });
  }

  /**
   * Get Interactive Widgets array
   */
  private getInteractiveWidgets(): InteractiveWidget[] {
    // Component can be standalone or included in myDashboard
    const component = this.myDashboardComponent
      ? this.myDashboardComponent
      : this.component;
    const page = this.myDashboardPage ? this.myDashboardPage : this.page;

    const interactiveWidgetsModel = component.getModels();
    this.interactiveWidgetsParams = component.getParameters();
    const interactiveWidgets = page
      .getContent(
        interactiveWidgetsModel.InteractiveWidgets ||
          interactiveWidgetsModel.interactiveWidgets ||
          interactiveWidgetsModel.document
      )
      ?.getData()?.interactiveWidget;
    interactiveWidgets?.forEach((widget: InteractiveWidget) => {
      widget.imgSrc = this.widenService.getWidenAssetUrl(
        widget.logoImage?.widenAsset
      );
      widget.interactiveConfigImpl = JSON.parse(widget.interactiveConfig);
      widget.isFirmAllowed = this.interactiveWidgetServiceService.getIsFirmAllowed(
        widget.firmName,
        this.profileService.getUserProfile()
      );
      widget.labelsImpl = interactiveWidgetsModel[widget.labels];
      widget.isDisplayed$ = this.isWidgetDisplayed$(widget?.loggedInOnly);
      widget.theme = widget?.theme || this.interactiveWidgetsParams?.theme;
      widget.customBgColor =
        widget?.customBgColor || this.interactiveWidgetsParams?.customBgColor;
      widget.promoAsset = widget?.additionalPromoAsset?.widenDocument;
      widget.marginBottom =
        widget?.marginBottom && widget?.marginBottom !== 'none'
          ? ' spacing--' + widget?.marginBottom
          : '';
      widget.buttonLink = this.getLinkUrl(page, widget?.button);
    });
    return interactiveWidgets;
  }

  /**
   * Check widget display
   * @param loggedInOnly - boolean from widget config
   */
  private isWidgetDisplayed$(loggedInOnly = false): Observable<boolean> {
    if (this.isEditMode) {
      // Display widget in edit mode
      return of(true);
    }
    if (loggedInOnly) {
      return this.isLoggedIn$;
    }
    return of(true);
  }

  /**
   * Get Columns Layout
   * @param widget - InteractiveWidget
   * @param widgetsWidth - component width as number
   * @returns - columns layout as Observable number
   */
  private getColumnsLayout$(
    widget: InteractiveWidget,
    widgetsWidth: number
  ): Observable<number> {
    if (widgetsWidth > 1024 && widget?.additionalPromoAsset) {
      return of(widget?.imgSrc ? 3 : 2);
    }
    return of(widget?.imgSrc ? 2 : 1);
  }

  /**
   * Get Internal Link Url
   * @param page - BR Page object
   * @param button - button linkCollection object
   * @returns - URL string or null
   */
  private getLinkUrl(
    page: Page,
    button: { linkCollection: LinkCollection[] }
  ): string | null {
    if (!button?.linkCollection || button.linkCollection.length === 0) {
      return null;
    }
    if (button.linkCollection[0].url) {
      return button.linkCollection[0].url;
    }
    const ref = button.linkCollection[0]?.document;
    const url = page?.getContent(ref)?.getUrl();
    return url || null;
  }

  /**
   * Add Password button click
   * @param event - MouseEvent
   */
  public addPassword(): void {
    logger.debug('Add Password');
    const linkText = this.resendEmail
      ? this.translateService.instant(
          'signin.add-password-resend-confirmation-email'
        )
      : this.translateService.instant('signin.fastTrackRegistrationButton');
    this.launchDarklyService.trackClick(
      LAUNCH_DARKLY_METRICS_NAME.PAT_BUTTON_CLICK
    );
    this.trackEvent(linkText);
    if (this.resendEmail) {
      this.fastTrackRegistrationService.setResendActivationEmailFlag$(true);
    }
    this.fastTrackRegistrationService.setIsRegistrationModalVisible$(true);
  }

  /**
   * open sign in modal
   */
  public openSignInModal() {
    this.trackEvent(this.translateService.instant('signin.signIn'));
    this.launchDarklyService.trackClick(
      LAUNCH_DARKLY_METRICS_NAME.PAT_BUTTON_CLICK
    );
    this.signInService.showSignInModal();
  }

  /**
   * call the GA event for tracking the button clicks
   */
  private trackEvent(linkText: string) {
    this.analyticsService.trackEvent({
      event: 'callout_click',
      detailed_event: 'Callout Clicks',
      event_data: {
        category: '',
        link_id: this.interactiveWidgetsParams?.variation,
        link_text: linkText,
        link_type: 'Standard Link',
        link_url: this.windowRef.location.href,
      },
      link_text: linkText,
      name: linkText,
      link_url: this.windowRef.location.href,
      type: 'PAT',
    });
  }

  /**
   * initializing the launch darkly only when we are showing pcs tool
   */
  private initializeLaunchDarkly(): void {
    this.personalisationService
      .getPersonalData$()
      .pipe(take(1))
      .subscribe((personalData: PersonalisationPersonalData) => {
        // initialize the launch darkly once we have user data
        // if this will rolled out then move the initialization to the personalisation service
        if (
          !this.launchDarklyService.isInitialised &&
          !this.isEditMode &&
          this.interactiveWidgets[0].isFirmAllowed
        ) {
          this.launchDarklyService.initialize(personalData);
          this.patToolVariation$ = this.launchDarklyService.getFlag$(
            'Test_PAT_Tool_Content_Variations',
            'v1'
          );
        }
        if (this.launchDarklyService.isInitialised) {
          this.patToolVariation$ = this.launchDarklyService.getFlag$(
            'Test_PAT_Tool_Content_Variations',
            'v1'
          );
        }
      });
  }
}
