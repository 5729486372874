import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { SegmentId } from '@types';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import { AppStateService, Redirect } from './app-state.service';
import { FirmRole, ProfileSummary } from './profile.interface';
import { SegmentService } from './segment.service';
import { StorageService } from './storage.service';

const logger = Logger.getLogger('RedirectService');

@Injectable({
  providedIn: 'root',
})
export class RedirectService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private redirects: Redirect[];

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private appState: AppStateService,
    private segmentService: SegmentService,
    private storageService: StorageService
  ) {
    logger.debug('constructor()');
    this.redirects = this.appState.getRedirects();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // checks if current url should be redirected based on various conditions
  // returns new url string if redirect should go ahead
  // returns null if no redirect should happen
  public getRedirect = async (
    fromUrl: string,
    updateUrl = false
  ): Promise<string | null> => {
    fromUrl = fromUrl.replace(/\?.*/, ''); // remove request parameters before matching
    logger.debug('getRedirect()', this.redirects, fromUrl);
    if (!this.redirects?.length) {
      // no redirects set up for channel, so return immediately
      return null;
    }

    // initially match redirects on URL only. Fail fast if none
    let redirects: Redirect[] = this.redirects.filter(
      (redirect: Redirect): boolean => fromUrl === redirect.from
    );
    if (!redirects.length) {
      // none of current redirects match on url
      return null;
    }

    // matching redirects found

    // don't get segment unless a condition requires it
    if (this.hasSegmentConditions(redirects)) {
      // get Segment
      const segmentId: SegmentId = await this.segmentService.getCurrentSegmentIdPromise();
      logger.debug('segmentId', segmentId);

      // filter on segmentId if it's a condition
      redirects = redirects.filter((redirect: Redirect): boolean => {
        if (!(redirect.conditions?.segments?.length > 0)) {
          return true;
        }
        return redirect.conditions.segments.includes(segmentId);
      });
      if (!redirects.length) {
        // all remaining redirects fail segment check
        return null;
      }
    }

    // don't get firm token unless a condition requires it
    if (this.hasFirmToken(redirects)) {
      const firmToken: FirmRole = await this.storageService.getFirmToken();
      if (firmToken) {
        // filter on firm token if it's a condition
        redirects = redirects.filter((redirect: Redirect): boolean => {
          if (!(redirect.conditions?.firm?.length > 0)) {
            return true;
          }
          return redirect.conditions?.firm.includes(firmToken);
        });
      } else {
        // remove firm token rules
        redirects = redirects.filter((redirect: Redirect): boolean => {
          return !redirect.conditions?.hasFirmToken;
        });
      }
    }
    if (!redirects.length) {
      // all remaining redirects fail firm check
      return null;
    }

    // don't get profile unless a condition requires it
    if (this.hasProfileConditions(redirects)) {
      // get ProfileSummary
      const profileSummary: ProfileSummary = await this.storageService.retrieveProfileSummary();
      logger.debug('profileSummary', profileSummary);

      // filter on isLoggedIn if it's a condition
      redirects = redirects.filter((redirect: Redirect): boolean => {
        if (!redirect.conditions || !('isLoggedIn' in redirect.conditions)) {
          return true;
        }
        return redirect.conditions.isLoggedIn === profileSummary.isLoggedIn;
      });

      // filter on isIdentified if it's a condition
      redirects = redirects.filter((redirect: Redirect): boolean => {
        if (!redirect.conditions || !('isIdentified' in redirect.conditions)) {
          return true;
        }
        return redirect.conditions.isIdentified === profileSummary.isIdentified;
      });

      if (!redirects.length) {
        // all remaining redirects fail isLoggedIn check
        return null;
      }

      // filter on login source if it's a condition
      redirects = redirects.filter((redirect: Redirect): boolean => {
        if (!(redirect.conditions?.source?.length > 0)) {
          return true;
        }
        return redirect.conditions.source.includes(profileSummary.source);
      });
      if (!redirects.length) {
        // all remaining redirects fail login source check
        return null;
      }

      // TODO: this condition hasn't been implemented anywhere yet
      // filter on firm if it's a condition
      // redirects = redirects.filter((redirect: Redirect): boolean => {
      //   if (!(redirect.conditions?.firm?.length > 0)) {
      //     return true;
      //   }
      //   return redirect.conditions.firm.includes(
      //     profileSummary.firm as FirmRole
      //   );
      // });
      // if (!redirects.length) {
      //   // all remaining redirects fail firm check
      //   return null;
      // }

      // filter on firmId if it's a condition
      redirects = redirects.filter((redirect: Redirect): boolean => {
        if (!(redirect.conditions?.firmId?.length > 0)) {
          return true;
        }
        return redirect.conditions.firmId.includes(profileSummary.firmId);
      });
      if (!redirects.length) {
        // all remaining redirects fail firmId check
        return null;
      }
    }

    // redirect matches all criteria

    // update browser url if requested
    if (updateUrl) {
      const newUrl = `${this.appState.getSpaBaseUrl()}${redirects[0].to}?${
        this.windowRef.location.search
      }#${this.windowRef.location.hash}`;

      logger.debug('update url in browser to: ', newUrl);
      this.windowRef.history.replaceState(null, '', newUrl);
    }

    return redirects[0].to;
  };

  private hasSegmentConditions = (redirects: Redirect[]): boolean =>
    redirects.some(
      (redirect: Redirect): boolean => redirect.conditions?.segments?.length > 0
    );

  private hasProfileConditions = (redirects: Redirect[]): boolean =>
    redirects.some(
      (redirect: Redirect): boolean =>
        (redirect.conditions && 'isLoggedIn' in redirect.conditions) ||
        (redirect.conditions && 'isIdentified' in redirect.conditions) ||
        redirect.conditions?.source?.length > 0 ||
        // redirect.conditions?.firm?.length > 0 ||
        redirect.conditions?.firmId?.length > 0
    );

  private hasFirmToken = (redirects: Redirect[]): boolean =>
    redirects.some(
      (redirect: Redirect): boolean => redirect.conditions?.hasFirmToken
    );
}
