<div class="row">
  <div class="col">
    <div edsGrid>
      <eds-title
        [title]="content.title"
        size="h3"
        theme="dark"
        edsSpacing="lg"
      ></eds-title>
      <div
        *ngIf="isTwoCol"
        edsSubGrid="layout-1"
        edsSubGridTablet="layout-1"
        edsSubGridTabletGap="sm"
        edsSubGridGap="sm"
      >
        <ng-container [ngTemplateOutlet]="avatarProfileBlock"></ng-container>
      </div>

      <div *ngIf="!isTwoCol">
        <ng-container [ngTemplateOutlet]="avatarProfileBlock"></ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #avatarProfileBlock>
  <ng-container *ngFor="let avatar of content.profilesItems; let i = index">
    <!-- Supporting Core & Blog's profile avatar object -->
    <eds-avatar
      [showImage]="avatar.isShowImage"
      [title]="avatar.fullName || avatar.displayName"
      [text1]="avatar.overview || avatar.role"
      [emailId]="avatar.email || avatar.emailAddress"
      [imgSrc]="avatar.profileImageUrl"
      fallbackImgSrc="/assets/images/ben_head.png"
      [href]="avatar.profileLink && navigateToAvatar ? avatar.profileLink : ''"
      [scheduleAppointmentText]="appointmentText"
      [scheduleAppointmentUrl]="appointmentUrl"
      edsSpacing="lg"
      [signInRequired]="signInRequired | ftIsSignInRequired | async"
    >
    </eds-avatar>
  </ng-container>
</ng-template>
