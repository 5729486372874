<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div>
      <eds-section-header
        [level]="3"
        theme="basic"
        size="h3"
        [title]="heading"
        [asOfLabel]="'products.as-of' | translate: brConfig?.fundId"
        [asOfDate]="fundPerformanceData?.performanceDates?.asOfDate"
        [analyticsName]="heading"
        [tooltip]="tooltips['products.cumulative-total-returns-section']"
        edsSpacing="sm"
      >
        <eds-footnote
          placement="Cumulative:CumulativePerformance"
        ></eds-footnote>
      </eds-section-header>
      <div *ngIf="isError">
        <div>
          <span>
            {{ "products.no-performance-data" | translate: brConfig.fundId }}
          </span>
        </div>
      </div>
      <div *ngIf="!isError && fundPerformanceData?.tableData?.length">
        <div edsFlexbox flexDirection="row">
          <div
            *ngIf="
              fundPerformanceData?.graphData &&
              !fundPerformanceConfig.hideChart &&
              chartCalculationToggle.length > 1
            "
          >
            <eds-toggle
              [toggleButtons]="chartCalculationToggle"
              (toggleActive)="onChartCalculationUpdate($event)"
            ></eds-toggle>
          </div>
          <div
            edsPadding
            edsPaddingLeft="md"
            *ngIf="periodEndToggle.length > 1"
          >
            <eds-toggle
              [toggleButtons]="periodEndToggle"
              (toggleActive)="onPeriodEndUpdate($event)"
              [ariaControls]="'cumulativeData'"
            ></eds-toggle>
          </div>
        </div>
        <br />
        <ng-container *ngIf="performanceStatusCaveat">
          <eds-proximal
            edsSpacing="lg"
            edsPadding
            edsPaddingBottom="sm"
            [placement]="performanceStatusCaveat"
          ></eds-proximal>
          <br />
        </ng-container>
        <eds-proximal
          placement="PerformanceCumulativeProximalTop"
        ></eds-proximal>
        <ng-container
          *ngIf="
            fundPerformanceData?.graphData && !fundPerformanceConfig.hideChart
          "
        >
          <eds-proximal
            placement="PerformanceCumulativeChartProximalTop"
          ></eds-proximal>
          <ft-fund-performance-cumulative-graph
            [seriesData]="fundPerformanceData.graphData"
            [graphNames]="fundPerformanceData.graphNames"
            [chartCalculationOptions]="
              fundPerformanceConfig.chartCalculationOptions
            "
            [selectedChartCalculation]="fundPerformanceConfig.chartCalculation"
            [valuePrefix]="fundPerformanceConfig.shareClassCurrency"
            [dateRange]="fundPerformanceData.performanceDateRange"
            [customHistory]="fundPerformanceConfig.customHistory"
            [show6m15yr]="fundPerformanceConfig.show6m15yr"
            (dateRangeSelectionEvent)="onDateRangeSelection($event)"
          >
          </ft-fund-performance-cumulative-graph>

          <eds-proximal
            placement="PerformanceCumulativeChartProximalBottom"
          ></eds-proximal>
        </ng-container>
        <br />
        <ft-fund-performance-cumulative-table
          [fundPerformanceData]="fundPerformanceData?.tableData"
          [fundPerformanceDates]="fundPerformanceData?.performanceDates"
          [fundPerformanceConfig]="fundPerformanceConfig"
          [tableLabel]="fundPerformanceData?.tableLabel"
          [fundId]="brConfig.fundId"
          [containerId]="'cumulativeData'"
        >
        </ft-fund-performance-cumulative-table>
        <div *ngIf="fundPerformanceConfig.showMifid">
          <eds-title size="h6" theme="basic" [isLight]="true">
            {{ "products.perf-inception-mifid" | translate: brConfig.fundId }}
            {{ fundPerformanceData.performanceDates.inceptionDate }}
            {{
              "products.perf-inception-mifid-end" | translate: brConfig.fundId
            }}
          </eds-title>
        </div>
        <eds-expand-collapse
          [collapsedButton]="
            'products.view-all-class-perf' | translate: brConfig.fundId
          "
          [expandedButton]="
            'products.view-all-class-perf' | translate: brConfig.fundId
          "
          top="true"
          paddingNone="true"
          borderNone="true"
          edsSpacing="md"
          *ngIf="
            fundPerformanceData?.allShareClassesTableData &&
            fundPerformanceData?.allShareClassesTableData.length > 0
          "
        >
          <ft-fund-performance-cumulative-table
            [fundPerformanceData]="
              fundPerformanceData?.allShareClassesTableData
            "
            [fundPerformanceDates]="
              fundPerformanceData?.allShareClassesTableData[0]
            "
            [fundPerformanceConfig]="fundPerformanceConfig"
            [tableLabel]="fundPerformanceData?.tableLabel"
            [fundId]="brConfig.fundId"
            [isAllShareClasses]="true"
          >
          </ft-fund-performance-cumulative-table>
        </eds-expand-collapse>
        <eds-expand-collapse
          [collapsedButton]="
            'products.view-historial-perf' | translate: brConfig.fundId
          "
          [expandedButton]="
            'products.view-historial-perf' | translate: brConfig.fundId
          "
          top="true"
          paddingNone="true"
          borderNone="true"
          edsSpacing="md"
          *ngIf="fundPerformanceConfig.showHistorical"
        >
          <eds-section-header
            [level]="3"
            theme="basic"
            size="h5"
            edsSpacing="sm"
          >
            {{ "products.performance-hist-cumulative-heading" | translate }}
          </eds-section-header>
          <div
            edsSpacing="md"
            edsFlexbox
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <eds-toggle
              [ariaControls]="'historicalData'"
              [toggleButtons]="historicalPeriodEndToggle"
              (toggleActive)="onHistoricalPeriodEndUpdate($event)"
              *ngIf="historicalPeriodEndToggle.length > 1"
            ></eds-toggle>
            <div edsFlexbox alignItems="center" justifyContent="flex-end">
              <eds-month-picker
                [label]="'products.calendar-from' | translate"
                [placeholder]="datePickerFormat"
                [min]="minDateStd"
                [max]="maxDateStd"
                [value]="selectedDateStd"
                [format]="datePickerFormat"
                (selected)="onHistoricalDateSelection($event)"
                [opens]="'left'"
                [required]="true"
                [errorMsgObj]="errorMsgObj"
                [calendarHeading]="'common.month-calendar-heading' | translate"
                [closeLabel]="'common.month-close' | translate"
                [prevButtonLabel]="'common.month-previous-button' | translate"
                [nextButtonLabel]="'common.month-next-button' | translate"
                [toggleButtonLabel]="'common.month-toggle-button' | translate"
                [toggleButtonChangeLabel]="
                  'common.month-change-button' | translate
                "
                [selectedDateLabel]="'common.month-selected-date' | translate"
                (openPanel)="onOpenMonthPicker($event)"
              ></eds-month-picker>
            </div>
          </div>
          <ft-fund-performance-cumulative-table
            [fundPerformanceData]="historicalFundPerformanceData?.tableData"
            [fundPerformanceDates]="
              historicalFundPerformanceData?.performanceDates
            "
            [fundPerformanceConfig]="fundPerformanceConfig"
            [tableLabel]="historicalFundPerformanceData?.tableLabel"
            [fundId]="brConfig.fundId"
            [isAllShareClasses]="false"
            [showLegends]="false"
            [containerId]="'historicalData'"
            *ngIf="
              historicalFundPerformanceData?.tableData &&
              historicalFundPerformanceData.tableData?.length > 0
            "
          >
          </ft-fund-performance-cumulative-table>
          <div
            *ngIf="
              !historicalFundPerformanceData?.tableData ||
              historicalFundPerformanceData.tableData?.length === 0
            "
          >
            <div>
              <h6>
                {{
                  "products.no-performance-hist-data"
                    | translate: brConfig.fundId
                }}
              </h6>
            </div>
          </div>
        </eds-expand-collapse>
        <ng-container *ngIf="fundPerformanceData?.tableData?.length">
          <eds-proximal
            placement="PerformanceCumulativeProximalBottom"
          ></eds-proximal>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBlock>
  <h5>Fund Performance Component - Cumulative</h5>
</ng-template>
