import { NotificationTypes } from '@accounts/accounts-alerts/accounts-alerts.interface';
import { Reference } from '@bloomreach/spa-sdk';
import { WidenAsset } from '@types';

export interface ResourceBundleModels {
  'ft.core.countries'?: Record<Key, string>;
  'ft.core.literatures'?: Record<Key, string>;
  'ft.core.common'?: Record<Key, string>;
  'ft.core.languages'?: Record<Key, string>;
  siteName?: string;
}
type Key = string;

export interface SiteAlertContent {
  id?: string;
  displayName?: string;
  subTitle?: string;
  title?: string;
  canClose?: boolean;
  ButtonCompound?: ButtonCompound[];
  message?: string;
  messageLink?: MessageLink;
  type?: NotificationTypes;
  displayOptions?: string;
  pageTypes: string[];
  segments: string[];
  firms?: string[];
}

interface ButtonCompound {
  button: SiteAlertButtons;
}

interface SiteAlertButtons {
  ButtonStyle?: string;
  LinkCompound?: LinkCompound;
}

interface LinkCompound {
  linkCollection: LinkCollections;
}
export interface LinkUrl {
  linkCollection: LinkCollections;
}
export interface LinkCollections {
  url?: string;
  title?: string;
  displayText?: string;
  external?: boolean;
  target?: string;
  document?: Reference;
  signInLink?: boolean;
  hideLockIcon?: boolean;
  widenAssetCompound?: WidenAsset;
}

export interface UtmCampainDetails {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
}

interface MessageLink {
  content?: string;
}

export interface PageContainerGridRow {
  layout?: string;
  cssClass?: string;
}
