import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AnalyticsService,
  ModalService,
  SimpleModalComponent,
  SimpleModalConfig,
} from '@frk/eds-components';
import { IUserProfile, ProfileSummary } from '@services/profile.interface';
import { ProfileService } from '@services/profile.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { PcsToolService } from './pcs-tool.service';
import { Logger } from '@utils/logger';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '@services/storage.service';
import {
  LAUNCH_DARKLY_METRICS_NAME,
  PCS_ACCEPT_FLAG,
  USER_GROUP,
} from '@utils/app.constants';
import { ServerCookieService } from '@services';
import { InteractiveWidget } from '@marketing/interactive-widgets/interactive-widgets.interface';
import { InteractiveWidgetServiceService } from '@marketing/interactive-widgets/interactive-widget-service.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';

const logger = Logger.getLogger('PCSToolComponent');

export interface PCSToolConfig {
  pcsDomain: string;
  pcsApiKey: string;
  launchPcsUrl: string;
  additionalConfig?: {
    contentType: string;
    interactiveWidget: Array<InteractiveWidget>;
  };
}

@Component({
  selector: 'ft-pcs-tool',
  templateUrl: './pcs-tool.component.html',
})
export class PcsToolComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  public pcsAcceptFlag: boolean;
  public modalConfig: SimpleModalConfig;
  public profile: IUserProfile;
  public isLoggedIn: boolean;
  public content: string;
  public brLabels: any;
  public isFirmAllowed = true;
  public isBypassUser: boolean;

  private pcsDomain: string;
  private pcsApiKey: string;
  private launchPcsUrl: string;
  private oAuthToken: string;
  public isPcsConfigArray = false;
  public pcsConfigArray: PCSToolConfig[];
  public currentPcsElement: PCSToolConfig;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('modal', { static: false }) modal: SimpleModalComponent;
  @Input() readonly pcsConfig?: PCSToolConfig;
  @Input() readonly pcsLabels?: object;
  @Input() readonly patToolVariation?: string;

  constructor(
    private modalService: ModalService,
    private profileService: ProfileService,
    private pcsToolService: PcsToolService,
    private renderer2: Renderer2,
    private cookieService: CookieService,
    private serverCookieService: ServerCookieService,
    private changeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
    private interactiveWidgetServiceService: InteractiveWidgetServiceService,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(DOCUMENT) readonly documentRef: Document,
    private launchDarklyService: LaunchDarklyService
  ) {
    super();
    this.serverCookieService
      .getOauthToken$()
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((token: string) => {
        this.oAuthToken = token;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.pcsConfig && this.pcsLabels) {
      this.populate(this.pcsConfig, this.pcsLabels);
    }
  }

  /**
   * Called from interactive widget entry dynamic component
   * @param config - from bloomreach interactive widget component
   * @param labels Br Labels
   */
  public populate(config: PCSToolConfig, labels: any): void {
    this.pcsConfigArray = Array.isArray(config) ? config : [config];
    let firmNames = [];
    // Overriding pcsConfigArray if pcs tool is called from interactive widget document
    if (
      this.pcsConfigArray?.length === 1 &&
      this.pcsConfigArray[0].additionalConfig?.interactiveWidget.length > 0
    ) {
      this.pcsConfigArray = [
        JSON.parse(
          config.additionalConfig.interactiveWidget[0].interactiveConfig
        ),
      ];
      firmNames = config.additionalConfig.interactiveWidget[0].firmName;
    }

    this.brLabels = labels;
    this.setModalData();

    this.content = this.brLabels?.pcsTool_content;

    this.profileService
      .getUserProfile$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profileData: IUserProfile) => {
        this.profile = profileData;
        // If PCS Flag isn't present then consider it as N
        this.pcsAcceptFlag = profileData?.profileInfo?.pcsAcceptFlag === 'Y';
        this.isLoggedIn = true;
        this.storageService
          .retrieve(USER_GROUP, true)
          .then((userGroup: string) => {
            this.profile.profileInfo.userGroup = userGroup;
            // Set user group from validateSession
          });
        this.isFirmAllowed = this.interactiveWidgetServiceService.getIsFirmAllowed(
          firmNames,
          profileData
        );
        this.isBypassUser = this.profileService.isBypass(true);
        this.changeDetectorRef.detectChanges();
      });
  }

  public showInitialTerms(pcsConfigElement?: PCSToolConfig): void {
    // WDE-4593 - Analytics event.
    this.analyticsService.trackEvent({
      event: 'callout_click',
      detailed_event: 'Callout Clicks',
      event_data: {
        category: '',
        link_id: this.patToolVariation,
        link_url: pcsConfigElement?.launchPcsUrl,
        link_type: 'Standard Link',
        link_text: 'Access Now',
      },
      link_url: pcsConfigElement?.launchPcsUrl,
      name: 'Access Now',
      link_text: 'Access Now',
      type: 'PAT',
    });

    // track the click event in launch darkly
    this.launchDarklyService.trackClick(
      LAUNCH_DARKLY_METRICS_NAME.PAT_BUTTON_CLICK
    );

    this.currentPcsElement = pcsConfigElement;
    // NGC-11990: Don't show T&C popup again on refresh, if a user has already accepted
    if (this.pcsAcceptFlag || this.cookieService.check(PCS_ACCEPT_FLAG)) {
      this.openTool();
    } else {
      this.modalService.open('termsModal1', this.modalConfig);
    }
  }

  private openTool(): void {
    const request = {
      Authorization: `Bearer ${this.oAuthToken}`,
    };
    this.postInNewTab(request, this.currentPcsElement.launchPcsUrl);
  }

  public declineTerms1(): void {
    this.modalService.close('termsModal1');
  }

  public acceptTerms1(): void {
    this.saveToDB();
  }

  /**
   * Save users terms and conditions acceptance
   */
  private saveToDB(): void {
    if (
      this.currentPcsElement.pcsDomain &&
      this.currentPcsElement.pcsApiKey &&
      this.profile?.profileInfo.userSysNo &&
      this.profile?.profileInfo.userGroup &&
      this.profile?.profileInfo.userId
    ) {
      this.pcsToolService
        .saveTermsAcceptance$(
          this.currentPcsElement.pcsDomain,
          this.profile?.profileInfo,
          this.currentPcsElement.pcsApiKey,
          this.oAuthToken
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.storeProfileSummary();
            this.openTool();
            this.closeModals();
          },
          () => {
            logger.error('Failed to save user preferences.');
            this.closeModals();
          }
        );
    } else {
      logger.debug('Missing config/profile info');
    }
  }

  private closeModals(): void {
    this.modalService.close('termsModal1');
  }

  private setModalData(): void {
    this.modalConfig = {
      modalId: 'termsModal',
      closeBtnLabel: '',
      title: this.brLabels?.pcsToolModal1Title,
      themeTitle: 'dark',
    };
  }

  /**
   * Fires a post request and opens url in a new tab
   * @param obj - request obj
   * @param url - request url
   */
  private postInNewTab(obj: any, url: string): void {
    const mapForm: HTMLFormElement = this.renderer2.createElement('form');
    // target _blank to open in a new tab
    this.renderer2.setProperty(mapForm, 'target', '_blank');
    this.renderer2.setProperty(mapForm, 'method', 'POST');
    this.renderer2.setProperty(mapForm, 'action', url);
    Object.keys(obj).forEach((param) => {
      const mapInput = this.renderer2.createElement('input');
      this.renderer2.setProperty(mapInput, 'type', 'hidden');
      this.renderer2.setProperty(mapInput, 'name', param);
      this.renderer2.setAttribute(mapInput, 'value', obj[param]);
      this.renderer2.appendChild(mapForm, mapInput);
    });
    this.renderer2.appendChild(this.documentRef.body, mapForm);
    mapForm.submit();
    // remove the temporary element once form has been submitted
    this.renderer2.removeChild(this.documentRef.body, mapForm);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.renderer2.destroy();
  }
  /**
   * Store Profile Summary when savetoDB has successfully completed
   * so that user who hasn't logged in again,
   * doesn't see T&C modal in the session again.p
   */
  private storeProfileSummary() {
    const summary: ProfileSummary = {
      isLoggedIn: this.profile?.isLoggedIn,
      source: this.profile?.loginSource,
      role: this.profile?.profileInfo.role,
      webExperience: this.profile?.profileInfo.webExperience,
      firm: this.profile?.profileInfo.firm,
      accountsAccess: this.profile?.profileInfo.accountsAccess,
      dashboardUrl: this.profile?.profileInfo.dashboardUrl,
      pcsAcceptFlag: 'Y',
    };
    this.storageService.storeProfileSummary(summary);
    this.pcsAcceptFlag = true;
    this.cookieService.set(PCS_ACCEPT_FLAG, 'Y');
  }
}
