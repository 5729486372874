import { Nominal } from 'simplytyped';

// although this is just a string, giving at a named (branded) type helps documentation and make typesafe

// TODO: the product type values inputted to graphql queries are different form the producttype values returned
//  see graphql-fund-data.service.ts

/**
 * Possible product types are: "ETFs", "Money Market Funds", "Mutual Funds" etc
 */
export type ProductType = Nominal<string, 'ProductType'>;

// TODO: ProductTypes are also defined in ConfigurationId. Consider to use ConfigurationId as ProductTypes.
export enum ProductTypes {
  'TIF' = 'TIF',
  'MUTUAL_FUNDS' = 'Mutual Funds',
  'VARIABLE_INSURANCE' = 'Variable Insurance',
  'ETFs' = 'ETFs',
  'CLOSED_END_FUNDS' = 'Closed-End Funds',
  'MONEY_MARKET_FUNDS' = 'Money Market Funds',
  'MANAGED_ACCOUNTS' = 'Managed Accounts',
  'INDICES' = 'Indices',
  '529_PORTFOLIOS' = '529 Portfolios',
  'SEGREGATED' = 'Segregated',
  'INTERVAL_FUNDS' = 'Interval Funds',
  'TENDER_OFFER_FUNDS' = 'Tender Offer Funds',
  'UAE_FEEDER_FUNDS' = 'UAE Feeder Funds',
}
