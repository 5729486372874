import {
  Alert,
  AssetClass,
  CurrencyCode,
  FundCategory,
  FundId,
  FundUmbrella,
  ProductType,
  WebProductTaxonomy,
} from '@types';
import { InvestmentTeam } from './investment-team';
import { ShareClass } from './share-class';
import { Document } from './document';
import { Aum } from './aum';
import { Portfolio } from '@models';

export interface Product {
  fundId?: FundId;
  fundName?: string;
  countryCode?: string;
  fundNameForSorting?: string;
  assetClass?: AssetClass;
  fundCategory?: FundCategory;
  investmentCategory?: string;
  investmentManager?: string;
  sfdrCategory?: string;
  investmentVehicle?: string;
  fundUmbrella?: FundUmbrella;
  region?: string;
  investmentGroup?: string;
  etfType?: string;
  currencyCode?: CurrencyCode;
  strategy?: string;
  methodology?: string;
  fundFamily?: string;
  productType?: ProductType;
  // mapped from BR labels
  productTypeLabel?: string; // plural product type eg Mutual Funds/Exchange Traded Funds etc
  productTypeAbbr?: string; // abbreviated product type eg MF/ETF/SMA/MMF etc
  productTypeSingular?: string; // singular product type eg Mutual Fund/Exchange Traded Fund etc
  productTypeId?: string; // kebab case version for use in ids etc eg mutual-funds etc
  productTaxonomy?: WebProductTaxonomy;
  shareClasses?: ShareClass[];
  investmentTeam?: InvestmentTeam[];
  ppssAlert?: Alert;
  documents?: Document[];
  aum?: Aum;
  portfolio?: Portfolio;
  indexId?: number;
  score?: string;
  sortingOrder?: number; // sorting order for aum products
}
