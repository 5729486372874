import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsServicingRegistrationModalComponent } from './us-servicing-registration-modal.component';
import {
  ButtonModule,
  CheckboxModule,
  FlexboxModule,
  GridModule,
  LinkItemModule,
  PaddingModule,
  PanelModule,
  SafeHtmlModule,
  SignInModule,
  SimpleModalModule,
  SpacingModule,
  TextInputModule,
} from '@frk/eds-components';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@shared/translate/translate.module';

@NgModule({
  declarations: [UsServicingRegistrationModalComponent],
  imports: [
    CommonModule,
    SimpleModalModule,
    PanelModule,
    FlexboxModule,
    TextInputModule,
    ButtonModule,
    SpacingModule,
    PaddingModule,
    CheckboxModule,
    SafeHtmlModule,
    GridModule,
    FormsModule,
    NgxSpinnerModule,
    TranslateModule,
    SignInModule,
    LinkItemModule,
  ],
  exports: [UsServicingRegistrationModalComponent],
})
export class UsServicingRegistrationModalModule {}
