import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Link, WidenAsset } from '@types';
import { AppStateService, WidenService, WindowScrollService } from '@services';
import { LinkService } from '@services/link.service';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import {
  ChipFilter,
  DropdownItem,
  SelectedFilter,
  Chip,
  PaginationComponent,
  PagedRecords,
  PaginationPageChangedEvent,
} from '@frk/eds-components';
import {
  AdditionalEventFilter,
  Events,
  Filter,
  ProfileItem,
  Speaker,
} from './../events.type';
import { TranslateService } from '@shared/translate/translate.service';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import { Observable, Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ft-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  @ViewChild(PaginationComponent, { static: false })
  paginationComponent: PaginationComponent;

  public totalPages: number;
  public pageSize = { value: 6, label: '6' };
  public itemsPerPageOption: DropdownItem[] = [];
  public pageTitle: string;
  public hidePagination = false;

  public filters: Filter[] = [
    {
      name: 'firstCustomFilter',
      title: '',
      label: '',
      selectedText: 'selected',
      items: [],
      showCount: false,
    },
    {
      name: 'speaker',
      title: this.translateService.instant('common.title-speaker'),
      label: this.translateService.instant('common.title-speaker'),
      selectedText: 'selected',
      items: [],
      showCount: false,
      linkUrl: 'https://www.franklintempleton.com/articles',
    },
    {
      name: 'assetClass',
      title: this.translateService.instant('common.title-asset-class'),
      label: this.translateService.instant('common.title-asset-class'),
      selectedText: 'selected',
      items: [],
    },
    {
      name: 'secondCustomFilter',
      title: '',
      label: '',
      selectedText: 'selected',
      items: [],
      showCount: false,
    },
  ];

  public events: Events[];
  public filteredEvents: Events[] = [];
  public speakers: Speaker[] = [];
  public chipsFilters: ChipFilter[] = [];
  public visibleEvents: Events[] = [];
  public showFilters = false;
  private chipMap: Map<string, boolean>;

  private pagedRecords: PagedRecords<Events>;
  public currentPageNumber$: Observable<number>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private widenService: WidenService,
    private linkService: LinkService,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private titleService: Title,
    private scrollService: WindowScrollService
  ) {}

  ngOnInit(): void {
    this.hidePagination = this.component?.getParameters()?.hidePagination;
    this.pageSize = {
      value: this.component?.getParameters()?.pagination || 6,
      label: this.component?.getParameters()?.pagination || '6',
    };
    this.pagedRecords = new PagedRecords<Events>(this.pageSize.value);
    this.pageTitle = this.titleService.getTitle();
    this.showFilters = !(this.component?.getParameters()?.hideFilters ?? false);
    this.events = this.getEvents();
    if (this.showFilters) {
      this.setFilterData();
    }
    this.filteredEvents = this.events;
    // get valid filter categories
    this.filters = this.filters?.filter(
      (eventFilter) => eventFilter?.items?.length
    );

    if (!this.hidePagination) {
      this.pagedRecords.records = cloneDeep(this.filteredEvents);
      this.createDropdownItems();

      // Get total number of pages for pagination bar.
      this.getNumberOfPages$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((totalPages) => {
          this.totalPages = totalPages;
          this.paginationComponent?.updatePagination(totalPages);
        });

      this.getCurrentPage$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((events: Events[]) => {
          this.visibleEvents = events;
        });
      // Get current page number as observable.
      this.currentPageNumber$ = this.getCurrentPageNumber$();
    } else {
      this.visibleEvents = cloneDeep(this.filteredEvents);
    }
  }

  public onPageChange(pageEvent: PaginationPageChangedEvent): void {
    if (pageEvent.page && pageEvent.action !== 'load') {
      this.goToPage(pageEvent.page);

      this.scrollService.scrollToAnchor('eventScrollPosition');
    }
  }

  public onDropdownChange(dropdownItem: DropdownItem): void {
    this.pageSize = this.itemsPerPageOption.find(
      (options) => Number(options.label) === dropdownItem.value
    );
    this.pagedRecords.pageSize = this.pageSize.value;
    this.scrollService.scrollToAnchor('eventScrollPosition');
  }

  /**
   * Creates dropdown options for number of items.
   */
  private createDropdownItems(): void {
    this.itemsPerPageOption = [6, 9, 12].map((rows) => ({
      value: rows,
      label: String(rows),
    }));
  }

  private setFilterData(): void {
    let assetClasses: (string | DropdownItem)[] = [];
    let speakers: (Speaker | DropdownItem)[] = [];
    let firstCustomFilter: (AdditionalEventFilter | DropdownItem)[] = [];
    let secondCustomFilter: (AdditionalEventFilter | DropdownItem)[] = [];

    this.events?.forEach((event) => {
      if (event.assetClass) {
        assetClasses.push(event.assetClass);
      }
      event.speakers?.forEach((speaker) => speakers.push(speaker));
      if (event.additionalEventFilters.length) {
        if (event.additionalEventFilters[0]) {
          firstCustomFilter.push(
            event.additionalEventFilters[0] as AdditionalEventFilter
          );
        }

        if (event.additionalEventFilters[1]) {
          secondCustomFilter.push(event.additionalEventFilters[1]);
        }
      }
    });
    const customFilterTitles = [
      ...uniqBy(firstCustomFilter, 'filterTitle'),
      ...uniqBy(secondCustomFilter, 'filterTitle'),
    ];
    // Setting custom filter title & label as per the additional filters received from BR for each event
    this.setCustomFiltersLabel(customFilterTitles);

    assetClasses = this.assetClassFilters(uniqBy(assetClasses));
    speakers = sortBy(speakers, (user) => {
      return user.label;
    });
    speakers = this.speakerFilters(uniqBy(speakers, 'value'));
    firstCustomFilter = this.additionalFilters(
      uniqBy(firstCustomFilter, 'filterValue')
    ) as DropdownItem[];
    secondCustomFilter = this.additionalFilters(
      uniqBy(secondCustomFilter, 'filterValue')
    );

    this.filters = this.filters.map((filter) => {
      if (filter?.name === 'speaker') {
        filter.items = speakers as DropdownItem[];
      } else if (filter?.name === 'assetClass') {
        filter.items = assetClasses as DropdownItem[];
      } else if (filter?.name === 'firstCustomFilter') {
        filter.items = firstCustomFilter as DropdownItem[];
      } else if (
        filter?.name === 'secondCustomFilter' &&
        !assetClasses.length
      ) {
        filter.items = secondCustomFilter as DropdownItem[];
      }
      return filter;
    });
  }

  /**
   * This method sets the Label & Title for First & Second custom Filters
   * @param additionalEventFilters - AdditionalEventFilter's title array
   */
  private setCustomFiltersLabel(
    additionalEventFilters: AdditionalEventFilter[]
  ): void {
    if (additionalEventFilters[0]) {
      this.filters[0].title = additionalEventFilters[0].filterTitle;
      this.filters[0].label = additionalEventFilters[0].filterTitle;
    }

    if (additionalEventFilters[1]) {
      this.filters[3].title = additionalEventFilters[1].filterTitle;
      this.filters[3].label = additionalEventFilters[1].filterTitle;
    }
  }

  private speakerFilters(speakers: any[]): DropdownItem[] {
    return speakers.map((speaker) => {
      return {
        label: speaker.label,
        count: '',
        checked: false,
        value: speaker?.value?.trim().toLowerCase(),
        id: 'option-' + speaker?.value?.trim().toLowerCase(),
      };
    });
  }

  private assetClassFilters(assetClasses: string[]): DropdownItem[] {
    return assetClasses.map((assetClass) => {
      return {
        label: startCase(assetClass),
        count: '',
        checked: false,
        value: assetClass.trim(),
        id: 'option-' + assetClass.trim(),
      };
    });
  }

  private additionalFilters(
    additionalFilters: AdditionalEventFilter[]
  ): DropdownItem[] {
    return additionalFilters.map((additionalFilter: AdditionalEventFilter) => {
      return {
        label: additionalFilter?.filterValue,
        count: '',
        checked: false,
        value: additionalFilter?.filterValue,
        id:
          'option-' +
          additionalFilter?.filterValue
            ?.trim()
            .toLowerCase()
            .replace(/\s+/g, '-'),
      };
    });
  }

  /**
   * Get Content from BR
   */
  private getContentData() {
    const { document } = this.component.getModels<DocumentModels>();
    const content = document && this.page.getContent(document);
    return content?.getData();
  }

  private getSpeakers(speakers: any[]): Speaker[] {
    const allSpeakers: Speaker[] = [];
    speakers?.forEach((speaker) => {
      if (speaker?.profileRef) {
        allSpeakers.push(this.getSpeakerDetails(speaker?.profileRef));
      }
    });
    return allSpeakers;
  }
  /**
   * Get Profile details
   */
  private getSpeakerDetails(profileRef): Speaker {
    const profileData: ProfileItem = this.page
      .getContent(profileRef)
      .getData<ProfileItem>();

    // Process widen url
    if (profileData?.profileImage) {
      profileData.profileImageUrl = this.widenService.getWidenImageVariantUrl(
        this.widenService.getWidenAssetUrl(
          profileData.profileImage?.widenAsset
        ),
        'original',
        'webp'
      );
    }

    return {
      label: profileData.fullName,
      profileThumbnail: profileData.profileImageUrl,
      value: profileData.name,
    };
  }

  /**
   * Get Session Links
   */
  public getSessionLinks = (linkRef): Link => {
    let sessionLinks: any = {};
    if (linkRef) {
      linkRef.forEach((link) => {
        if (link.buttonStyle !== 'secondary') {
          sessionLinks = link.linkCompound.linkCollection[0];
          if (sessionLinks) {
            sessionLinks.presentationLinkURL = this.linkService?.getCTALink(
              this.page,
              link.linkCompound.linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        } else {
          sessionLinks.optionalLink = link.linkCompound.linkCollection[0];
          if (sessionLinks) {
            sessionLinks.optionalLink.optionalLinkUrl = this.linkService?.getCTALink(
              this.page,
              link.linkCompound.linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        }
      });
    }

    return sessionLinks;
  };

  /**
   * Get Events from content
   */
  public getEvents(): Events[] {
    const rawEvents =
      this.getContentData()?.sessions?.map((session) => {
        const speakers: Speaker[] = this.getSpeakers(session.speaker);
        if (session?.additionalEventFilter) {
          return {
            eventTitle: session.title,
            description: session.summary.content,
            speakers,
            links: this.getSessionLinks(session.button),
            assetClass: session.assetClass[0],
            additionalEventFilters: session.additionalEventFilter,
            logoUrl: this.widenService.getWidenAssetUrl(
              this.getLogo(session.imageLogo)?.widenDocument
            ),
            label: this.getLabels(speakers),
          };
        }

        return {
          eventTitle: session.title,
          description: session.summary.content,
          speakers,
          links: this.getSessionLinks(session.button),
          assetClass: session.assetClass[0],
          logoUrl: this.widenService.getWidenAssetUrl(
            this.getLogo(session.imageLogo)?.widenDocument
          ),
          label: this.getLabels(speakers),
        };
      }) || [];

    return rawEvents;
  }

  private getLabels(speakers: Speaker[]): string {
    return speakers
      .map((speaker) => {
        return speaker?.label;
      })
      .join(' & ');
  }

  private getLogo(imageLogo: any): WidenAsset {
    return imageLogo.find((logo) =>
      this.widenService.getWidenAssetUrl(logo?.widenDocument)
    );
  }

  public addChips(): ChipFilter[] {
    this.chipMap = new Map();
    const item = this.filters
      .map((filter) => {
        return {
          ...filter,
          items: filter.items.filter((filterItem) => filterItem.checked),
        };
      })
      ?.filter((filter) => {
        return filter?.items?.length > 0;
      })
      .map((filter) => {
        this.chipMap.set(filter.name, true);
        return {
          chips: filter.items.map((filterItem) => ({
            label: filter.title, // Mapping Filter Title to Chip Label
            value: filterItem.label, // Mapping filter item label to value
          })),
        };
      });
    return item;
  }

  public onSelectFilter(event: SelectedFilter): void {
    this.chipsFilters = this.addChips();
    this.updateFilteredEvent();
  }

  private updateFilteredEvent(): void {
    let speakerFilterSet: Set<string>;
    let assetClassFilterSet: Set<string>;
    let firstCustomFilterSet: Set<string>;
    let secondCustomFilterSet: Set<string>;

    this.filters?.forEach((filter) => {
      if (filter?.name === 'speaker') {
        speakerFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'assetClass') {
        assetClassFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'firstCustomFilter') {
        firstCustomFilterSet = this.buildFilterSet(filter?.items);
      } else if (
        filter?.name === 'secondCustomFilter' &&
        !assetClassFilterSet?.size
      ) {
        secondCustomFilterSet = this.buildFilterSet(filter?.items);
      }
    });

    this.filteredEvents = [
      ...this.events.filter((event) => {
        const isAssetClassValid =
          !this.chipMap.get('assetClass') ||
          !assetClassFilterSet ||
          assetClassFilterSet.has(event.assetClass);
        const areSpeakersValid =
          !this.chipMap.get('speaker') ||
          event.speakers?.some((speaker) =>
            speakerFilterSet.has(speaker?.value)
          );
        const customFilterOneSessionValid =
          !this.chipMap.get('firstCustomFilter') ||
          firstCustomFilterSet.has(
            event.additionalEventFilters[0]?.filterValue
          );
        const customFilterTwoSessionValid =
          !this.chipMap.get('secondCustomFilter') ||
          secondCustomFilterSet.has(
            event.additionalEventFilters[1]?.filterValue
          );

        return (
          isAssetClassValid &&
          areSpeakersValid &&
          customFilterTwoSessionValid &&
          customFilterOneSessionValid
        );
      }),
    ];

    if (!this.hidePagination) {
      this.pagedRecords.records = cloneDeep(this.filteredEvents);
    } else {
      this.visibleEvents = cloneDeep(this.filteredEvents);
    }
  }

  private buildFilterSet(filters: DropdownItem[]): Set<string> {
    const checkedFilters = filters?.filter((filter) => filter?.checked);
    if (checkedFilters?.length === 0) {
      return new Set(filters?.map((filter) => filter?.value));
    } else {
      return new Set(checkedFilters?.map((filter) => filter?.value));
    }
  }

  private resetEventToDefault(): void {
    this.filteredEvents = this.events;
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: filter?.items?.map((filterItem) => {
          return {
            ...filterItem,
            checked: false,
          };
        }),
      };
    });
    this.chipsFilters = [];
    if (!this.hidePagination) {
      this.pagedRecords.records = cloneDeep(this.filteredEvents);
    } else {
      this.visibleEvents = cloneDeep(this.filteredEvents);
    }
  }

  public resetFilter($event: any): void {
    this.resetEventToDefault();
  }

  public chipRemoved(chip: Chip): void {
    const removedFilterGroup = this.filters.find(
      (filter) => filter.title === chip.label
    );
    if (removedFilterGroup) {
      removedFilterGroup?.items?.forEach((item) => {
        if (item?.label === chip?.value) {
          item.checked = false;
        }
      });
      if (!removedFilterGroup?.items?.find((item) => item.checked)) {
        this.chipMap.delete(removedFilterGroup?.name);
      }
    }
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: [...filter.items],
      };
    });
    this.chipsFilters = this.chipsFilters.filter(
      (chipFilter) => chipFilter?.chips?.length > 0
    );
    this.updateFilteredEvent();
  }

  /**
   * Return total number of pages as observable.
   */
  public getNumberOfPages$(): Observable<number> {
    return this.pagedRecords.numberOfPages$;
  }

  /**
   * Return current page records as observable.
   */
  getCurrentPage$(): Observable<Events[]> {
    return this.pagedRecords.currentPage$;
  }

  /**
   * Return current page number as observable.
   */
  public getCurrentPageNumber$(): Observable<number> {
    return this.pagedRecords.pageNumber$;
  }

  /**
   * Trigger page number selection accordingly.
   * @param n page number selected
   */
  public goToPage(n: number): void {
    if (this.pagedRecords) {
      this.pagedRecords.pageNumber = n;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
