import { PortfolioChar, Product, ShareClass } from '@models';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { TranslateService } from '@shared/translate/translate.service';
import {
  AssetClass,
  ConfigurationId,
  CurrencyCode,
  FundCategory,
  FundId,
  FundUmbrella,
  ProductData,
  ProductDTO,
  ProductType,
  ProductTypes,
  WebProductTaxonomy,
} from '@types';
import { Logger } from '@utils/logger';
import { getNameForSorting } from '@utils/sort-utils';
import kebabCase from 'lodash/kebabCase';
import { AumMapper } from './aum.type.mapper';
import { InvestmentTeamsMapper } from './investment-team.type.mapper';
import { PortfolioCharMapper } from './portfolio.type.mapper';
import { ShareClassMapper } from './share-class.type.mapper';
import { Mapper, MapperParams } from './type.mapper';

const logger = Logger.getLogger('ProductsMapper');

export interface ProductsMapperParams extends MapperParams {
  runDataCheck: boolean;
}

export class ProductsMapper extends Mapper<Product[]> {
  constructor(
    protected mapperParams: ProductsMapperParams,
    protected mapperFactory: MapperFactory
  ) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    productsList: ProductDTO[],
    activeList?: string[],
    overrideFlag?: boolean
  ): Product[] {
    const productsData: Product[] = [];

    productsList.forEach((productDto, index) => {
      const product: Product = this.createMapper(ProductMapper).toDomain(
        productDto,
        activeList,
        overrideFlag
      );

      if (product) {
        productsData.push(product);
      }
    });

    // UDS-829 check for missing data
    // checks are only run on PPSS pages
    if (this.mapperParams.runDataCheck) {
      // NB: there will be further checks for site and product type
      // inside DataChecker before running checks
      logger.debug('Run PPSS Data Check');
      this.mapperParams.dataChecker?.checkForMissingData(
        productsData,
        this.mapperParams
      );
    }

    return productsData;
  }
}

// abbreviated product type eg "ETF"
export const productTypeAbbrMapper = (
  productType: ProductType,
  translateService?: TranslateService
): string => {
  if (!translateService) {
    return null;
  }
  switch (productType) {
    case ProductTypes.TIF:
      return translateService.instant(`products.TIF_abbr`);
    case ProductTypes.MUTUAL_FUNDS:
      return translateService.instant(`products.MF_abbr`);
    case ProductTypes.VARIABLE_INSURANCE:
      return translateService.instant(`products.VI_abbr`);
    case ProductTypes.ETFs:
      return translateService.instant(`products.ETF_abbr`);
    case ProductTypes.CLOSED_END_FUNDS:
      return translateService.instant(`products.CEF_abbr`);
    case ProductTypes.MONEY_MARKET_FUNDS:
      return translateService.instant(`products.MMF_abbr`);
    case ProductTypes.MANAGED_ACCOUNTS:
      return translateService.instant(`products.SMA_abbr`);
    case ProductTypes.INDICES:
      return translateService.instant(`products.INDICES_abbr`);
    case ProductTypes['529_PORTFOLIOS']:
      return translateService.instant(`products.529_abbr`);
    case ProductTypes.SEGREGATED:
      return translateService.instant(`products.SEGREGATED_abbr`);
    case ProductTypes.INTERVAL_FUNDS:
      return translateService.instant(`products.IF_abbr`);
    case ProductTypes.TENDER_OFFER_FUNDS:
      return translateService.instant(`products.TOF_abbr`);
    case ProductTypes.UAE_FEEDER_FUNDS:
      return translateService.instant(`products.UAFF_abbr`);
  }
};

// singular product type eg "Exchange Traded Fund"
export const productTypeSingularMapper = (
  productType: ProductType,
  translateService?: TranslateService
): string => {
  if (!translateService) {
    return null;
  }
  switch (productType) {
    case ProductTypes.TIF:
      return translateService.instant(`products.TIF_singular`);
    case ProductTypes.MUTUAL_FUNDS:
      return translateService.instant(`products.MF_singular`);
    case ProductTypes.VARIABLE_INSURANCE:
      return translateService.instant(`products.VI_singular`);
    case ProductTypes.ETFs:
      return translateService.instant(`products.ETF_singular`);
    case ProductTypes.CLOSED_END_FUNDS:
      return translateService.instant(`products.CEF_singular`);
    case ProductTypes.MONEY_MARKET_FUNDS:
      return translateService.instant(`products.MMF_singular`);
    case ProductTypes.MANAGED_ACCOUNTS:
      return translateService.instant(`products.SMA_singular`);
    case ProductTypes.INDICES:
      return translateService.instant(`products.INDICES_singular`);
    case ProductTypes['529_PORTFOLIOS']:
      return translateService.instant(`products.529_singular`);
    case ProductTypes.SEGREGATED:
      return translateService.instant(`products.SEGREGATED_singular`);
    case ProductTypes.INTERVAL_FUNDS:
      return translateService.instant(`products.IF_singular`);
    case ProductTypes.TENDER_OFFER_FUNDS:
      return translateService.instant(`products.TOF_singular`);
    case ProductTypes.UAE_FEEDER_FUNDS:
      return translateService.instant(`products.UAFF_singular`);
  }
};

// plural product type eg "Exchange Traded Funds"
// TODO: should probably change suffix from _fullname to _plural
export const productTypeLabelMapper = (
  productType: ProductType,
  translateService?: TranslateService
): string => {
  if (!translateService) {
    return null;
  }
  switch (productType) {
    case ProductTypes.TIF:
      return translateService.instant(`products.TIF_fullname`);
    case ProductTypes.MUTUAL_FUNDS:
      return translateService.instant(`products.MF_fullname`);
    case ProductTypes.VARIABLE_INSURANCE:
      return translateService.instant(`products.VI_fullname`);
    case ProductTypes.ETFs:
      return translateService.instant(`products.ETF_fullname`);
    case ProductTypes.CLOSED_END_FUNDS:
      return translateService.instant(`products.CEF_fullname`);
    case ProductTypes.MONEY_MARKET_FUNDS:
      return translateService.instant(`products.MMF_fullname`);
    case ProductTypes.MANAGED_ACCOUNTS:
      return translateService.instant(`products.SMA_fullname`);
    case ProductTypes.INDICES:
      return translateService.instant(`products.INDICES_fullname`);
    case ProductTypes['529_PORTFOLIOS']:
      return translateService.instant(`products.529_fullname`);
    case ProductTypes.SEGREGATED:
      return translateService.instant(`products.SEGREGATED_fullname`);
    case ProductTypes.INTERVAL_FUNDS:
      return translateService.instant(`products.IF_fullname`);
    case ProductTypes.TENDER_OFFER_FUNDS:
      return translateService.instant(`products.TOF_fullname`);
    case ProductTypes.UAE_FEEDER_FUNDS:
      return translateService.instant(`products.UAFF_fullname`);
  }
};

export class ProductMapper extends Mapper<Product> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    productDto: ProductDTO,
    activeList?: string[],
    overrideFlag?: boolean
  ): Product {
    let product: Product;
    const productShareClasses: ShareClass[] = [];
    const productPortfolioChars: PortfolioChar[] = [];
    const productType: ProductType = productDto.producttype as ProductType;
    const productTypeLabel: string = productTypeLabelMapper(
      productType,
      this.mapperParams?.translateService
    );

    productDto?.shareclass?.forEach((shareClassDto) => {
      const shareClass = this.createMapper(ShareClassMapper).toDomain(
        shareClassDto,
        productType,
        productDto.webprdcttaxonomy as WebProductTaxonomy,
        productDto.basecurrcode,
        productDto.fundname,
        activeList,
        overrideFlag
      );
      if (shareClass) {
        productShareClasses.push(shareClass);
      }
    });

    productDto?.portfolio?.portfoliochars?.forEach((portfolioCharDto) => {
      const portfolioChar = this.createMapper(PortfolioCharMapper).toDomain(
        portfolioCharDto
      );
      if (portfolioChar) {
        productPortfolioChars.push(portfolioChar);
      }
    });

    product = {
      fundId: productDto.fundid as FundId,
      fundName: productDto.fundname,
      countryCode: productDto.cntrycode,
      fundNameForSorting:
        productDto.fundname &&
        !this.mapperParams?.config.isSiteInternational() &&
        (this.mapperParams?.configurationName === ConfigurationId._529 ||
          this.mapperParams?.configurationName === ConfigurationId.SMA)
          ? getNameForSorting(productDto.fundname)
          : '',
      assetClass: productDto.assetclass as AssetClass,
      investmentCategory: productDto.invstmntcatg,
      fundCategory: productDto.fundcatg as FundCategory,
      investmentManager: productDto.invmangr,
      sfdrCategory: productDto.sfdrcategory,
      investmentVehicle: productDto.invvehcile,
      fundUmbrella: productDto.fundumbrlla as FundUmbrella,
      region: productDto.region,
      investmentGroup: productDto.invstmntgroup,
      currencyCode: productDto.basecurrcode as CurrencyCode,
      etfType: productDto.etftype,
      strategy: productDto.strategy,
      methodology: productDto.methodology,
      fundFamily: productDto.fundfamily,
      productType,
      productTypeAbbr: productTypeAbbrMapper(
        productType,
        this.mapperParams?.translateService
      ),
      productTypeLabel,
      productTypeSingular: productTypeSingularMapper(
        productType,
        this.mapperParams?.translateService
      ),
      productTypeId: kebabCase(productTypeLabel),
      productTaxonomy: productDto.webprdcttaxonomy as WebProductTaxonomy,
      shareClasses: productShareClasses,
      investmentTeam: productDto.investmentteam
        ? this.createMapper(InvestmentTeamsMapper).toDomain(
            productDto.investmentteam
          )
        : null,
      aum: productDto.aum
        ? this.createMapper(AumMapper).toDomain(productDto.aum)
        : null,
    };
    if (productPortfolioChars.length) {
      product.portfolio = {
        portfolioChars: productPortfolioChars,
      };
    }
    logger.debug(' ' + product.fundName + ' : ' + product.investmentVehicle);
    return product;
  }
}

// maps a Product object with single ShareClass into a ProductData object
export const mapProductToProductData = (product: Product): ProductData => {
  const { shareClasses, ...productRef } = product;
  const productData: ProductData = productRef;
  productData.shareClass = shareClasses[0];
  return productData;
};
