export interface EmailDocsRequestBody {
  emailIds: string[];
  literatureCodes: string[];
}
export interface EmailDocsResponse {
  description: string;
  message: string;
}
export interface EmailDetails {
  recipientEmailId: string;
}
export interface LiteratureContentDocument {
  url?: string;
  literatureCode?: string;
  title?: string;
  description?: string;
}
export interface LiteratureRequestBodyProfile {
  userId: string;
  role: string;
  webExperience: string;
  displayName: string;
  firm: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  email: string;
  businessKey: string;
  userSysNo: string;
  loginName: string;
  dealerNumber: string;
}
export interface MailRequestBody {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  workPhone?: string;
  emailAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: any;
  mailStateCode?: any;
  emailOrderStatus?: boolean;
  deliverTo?: string;
  zip?: string;
  userInfo?: UserProfile;
  cartItems?: CartDetails[];
}
export interface CartDetails {
  literatureCode?: string;
  qty?: number;
}
export interface MailFormValues {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: any;
  zip?: string;
  workPhone?: string;
  emailAddress?: string;
  deliverTo?: string;
  userInfo?: UserProfile;
  mailStateCode?: string;
}
export interface UserProfile {
  userId?: string;
  role?: string;
  webExperience?: string;
  displayName?: string;
  firm?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneNumber?: string;
  email?: string;
  businessKey?: string;
  userSysNo?: string;
  loginName?: string;
  dealerNumber?: string;
}
export interface MailResponseObject {
  orderConfirmationNumber?: string;
  lineItem?: LineItem[];
  submitOrderInformation?: SubmitOrderInformation;
}
export interface MailResponseError {
  code?: string;
  message?: string;
  description?: string;
}
export interface SubmitOrderInformation {
  firstName: string;
  lastName: string;
  middleName?: string;
  expressNumber: string;
  dealerNumber: string;
  workPhone: string;
  emailAddress: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateCode: string;
  mailStateCode: string;
  deliverTo?: string;
  emailOrderStatus: boolean;
  zip: string;
}

export interface LineItem {
  packageCode: string;
  literatureType: string;
  publicationDate: string;
  orderQuantity: number;
  status: any[];
}
export interface FundComentaryContent {
  documents: Documents;
}
export interface Documents {
  count?: string;
  document?: Document;
}
export interface Document {
  path: string;
  field: Field[];
  content: Content;
}
export interface Content {
  COMMENTARY: Commentary;
}
export interface Commentary {
  COMMENTARY_TYPE: string;
  COMMENTARY_TITLE: string;
  SPEAKERS: Speakers;
  FEATURED: string;
  HIGH_PROFILE_SYNOPSIS: string;
  HIGH_PROFILE_IMAGE: string;
  HIGH_PROFILE_ALT_TEXT: string;
  COMMENTARY_KEY_POINTS: string;
  FULL_COMMENTARY: string;
  PDF: string;
  External_PDF: string;
  BRIGHTCOVE_VIDEO_ID: string;
  STANDARD_LEGAL_INFORMATION: string;
  IMPORTANT_LEGAL_INFORMATION: string;
  MEDIA_TYPE: string;
  RUNNING_TIME: string;
  MEDIA_LINK: string;
  External_Media_Link: string;
  COMMENTARY_IMAGE: string;
  COMMENTARY_ALT_TEXT: string;
  RELATED_RESOURCES: RelatedResources;
  METADATA: MetaData;
}
export interface MetaData {
  WEBSITE: string;
  cms_historicalView: string;
  CALENDAR_YEAR: string;
  WHICH_QUARTER: string;
  FUND_NAME: string;
  FUND_NUMBER: string;
  ACTOR: string;
  START_DATE: string;
  STOP_DATE: string;
  DEPLOY_TO: string;
}
export interface RelatedResources {
  RESOURCE_NAME: string;
  RESOURCE_LINK: string;
  External_RR_Link: string;
}
export interface Speakers {
  SPEAKER_NAME: string;
  SPEAKER_TITLE: string;
}
export interface Field {
  metadataName: string;
  metadataValue: string;
}
