import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  LinkClickEvent,
  ResponsiveService,
  SimpleModalConfig,
} from '@frk/eds-components';
import {
  AccessRegisterStatus,
  PersonalisationAPIService,
  AccountsApiResponse,
  RegisterUserInfo,
  ResendConfResponse,
  FastTrackRegistrationService,
} from '@services';
import { PersonalisationPersonalData } from '@types';
import { Logger } from '@utils/logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('UsServicingRegistrationModalComponent');

@Component({
  selector: 'ft-us-servicing-registration-modal',
  templateUrl: './us-servicing-registration-modal.component.html',
  styleUrls: ['./us-servicing-registration-modal.component.scss'],
})
export class UsServicingRegistrationModalComponent
  implements OnInit, OnDestroy {
  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 - we need to accept more than 7 parameters in the constructor.
    private responsiveService: ResponsiveService,
    private personalisationService: PersonalisationAPIService,
    private fastTrackRegistrationService: FastTrackRegistrationService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService
  ) {
    // listen for handheld view
    this.isHandheld$ = this.responsiveService.isHandheld$();
    // listen for register modal
    this.isModalVisible$ = this.fastTrackRegistrationService.getIsRegistrationModalVisible$();
  }
  private passForm: FormGroup;
  private unSubscribe$: Subject<void> = new Subject<void>();
  private uSServicingPersonalData: PersonalisationPersonalData;
  private validationPatterns = {
    pattern1: /(.*[a-zA-Z].*[a-zA-Z].*)/, // Contains 2 letters,
    pattern2: /(.*\d.*\d.*)/, // Contains 2 numbers
    pattern3: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, // Contains special character
  };
  private initInvalidPasswordText: string;
  public isModalVisible$: Observable<boolean>;
  public modalConfig: SimpleModalConfig;
  public passwordType: 'text' | 'password' = 'password';
  public isHandheld$: Observable<boolean>;
  public submitDisabled = true;
  public invalidPassword: boolean;
  public tcCheckboxError = false;
  public showPasswordRules = true;
  public passwordSubmitted = false;
  public invalidPasswordText: string;
  public validPassRules = {
    rule1: false,
    rule2: false,
    rule3: false,
    rule4: false,
  };
  public showResendEmail = false;
  public showDeletedMessage = false;
  public registrationError = false;
  public isLoading = false;
  public isAlreadyRegisteredError = false;
  public isResentEmailSuccess = false;
  public registerToken: string;
  public registerUsrSysno: string;
  public emailValue: string;

  ngOnInit(): void {
    this.modalConfig = {
      modalId: 'addPasswordModal',
      themeTitle: 'basic',
      title: this.translateService.instant('signin.add-password-title'),
    };

    this.passForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(70),
        Validators.pattern(this.validationPatterns.pattern1),
        Validators.pattern(this.validationPatterns.pattern2),
        Validators.pattern(this.validationPatterns.pattern3),
      ]),
      tcCheckBox: new FormControl(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    });

    this.spinner
      .show('submitPasswordSpinner')
      .then(() => logger.debug('Submit Password Spinner set'));

    // Listen to personal data change
    this.personalisationService
      .getPersonalData$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((uSServicingPersonalData: PersonalisationPersonalData) => {
        this.uSServicingPersonalData = uSServicingPersonalData;
        this.emailValue = uSServicingPersonalData.email;
      });

    // Listen for sending confirmation email
    this.fastTrackRegistrationService
      .getResendActivationEmailFlag$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((activationEmailFlag: boolean) => {
        if (activationEmailFlag) {
          const registerUserInfo: RegisterUserInfo = this.fastTrackRegistrationService.getRegisterUserInfo();
          this.registerUsrSysno = registerUserInfo.registerUsrSysNo;
          this.registerToken = registerUserInfo.registerToken;
          this.passwordSubmitted = true;
          this.resendEmailProcess();
        }
      });
    this.initInvalidPasswordText = this.invalidPasswordText = this.translateService.instant(
      'signin.add-password-invalid-text'
    );
  }

  /**
   * Submit password action
   * @param event - Mouse Event
   */
  public submitPassword(event: MouseEvent): void {
    logger.debug('Submit Password:', event);
    this.getRegisterToken();
  }

  /**
   * Resend Email
   * @param event - MouseEvent
   */
  public resendEmail(event: LinkClickEvent | MouseEvent): void {
    logger.debug('Resend Email');
    this.resendEmailProcess();
  }

  /**
   *  Closing modal without action
   */
  public onModalClose(): void {
    this.resetPassForm();
    this.fastTrackRegistrationService.setIsRegistrationModalVisible$(false);
  }

  /**
   * Unmask/mask password field
   * @param event - boolean
   */
  public onMaskedIconClicked(event: boolean): void {
    logger.debug('Masked icon clicked:', event);
    if (event) {
      this.passwordType = 'password'; // NOSONAR: Not a real sonar issue typescript:S2068. It describes a password type.
    } else {
      this.passwordType = 'text'; // NOSONAR: Not a real sonar issue typescript:S2068. It describes a password type.
    }
  }

  /**
   * T&C checkbox action
   * @param event - boolean
   */
  public onCheckedChange(event: boolean): void {
    logger.debug('Checkbox:', event);
    this.passForm.setValue({
      password: this.passForm.getRawValue().password,
      tcCheckBox: event,
    });
    this.tcCheckboxError = !this.passForm.controls?.tcCheckBox?.value;
    this.submitDisabled = !this.passForm.valid;
  }

  /**
   * In password form field action
   */
  public onInputFocus(): void {
    if (this.invalidPassword) {
      this.showPasswordRules = true;
      this.invalidPasswordText = null;
    }
  }

  /**
   * Out of password form field action
   */
  public outInputFocus(): void {
    this.invalidPassword = !!this.passForm.controls?.password?.errors;
    if (this.invalidPassword) {
      this.showPasswordRules = false;
      this.invalidPasswordText = this.initInvalidPasswordText;
    } else {
      this.showPasswordRules = true;
      this.invalidPasswordText = null;
    }
  }

  /**
   * Password text validation
   * @param event - string
   */
  public onInputTextChange(event: string): void {
    logger.debug('Password field changed', event);
    this.passForm.setValue({
      password: event,
      tcCheckBox: this.passForm.getRawValue().tcCheckBox,
    });
    const errors: ValidationErrors = this.passForm.controls?.password?.errors;
    if (this.invalidPassword) {
      logger.debug('Password contains errors');
      this.invalidPassword = !!errors;
    }
    this.validPassRules.rule1 = !(
      errors?.minlength ||
      errors?.maxlength ||
      errors?.required
    );
    this.validPassRules.rule2 = this.validationPatterns.pattern1.test(event);
    this.validPassRules.rule3 = this.validationPatterns.pattern2.test(event);
    this.validPassRules.rule4 = this.validationPatterns.pattern3.test(event);
    this.submitDisabled = !this.passForm.valid;
  }

  /**
   * Save Password Process
   * @param registerToken - string
   */
  private savePasswordProcess(registerToken: string): void {
    const submittedPassword = this.passForm.get('password').value;
    this.isLoading = true;
    this.submitDisabled = true;
    this.changeDetector.detectChanges();
    this.fastTrackRegistrationService
      .submitAccountsPassword$(
        this.uSServicingPersonalData,
        submittedPassword,
        registerToken
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (response: AccountsApiResponse) => {
          logger.debug('API response', response);
          if (response?.body?.result?.message === 'Successful Operation') {
            this.registerUsrSysno = response?.body?.result?.usersysno;
            this.fastTrackRegistrationService.setRegisterUserInfo(
              this.registerUsrSysno,
              this.registerToken
            );
            this.showResendEmail = true;
            logger.debug('Password submit success');
            this.modalConfig.title = this.translateService.instant(
              'signin.add-password-title-submitted'
            );
            this.fastTrackRegistrationService.setRegistrationModalData$({
              isRegisterSuccess: true,
              usrSysNo: response?.body?.result?.usersysno,
            });
          } else {
            this.modalConfig.title = this.translateService.instant(
              'signin.add-password-title-error'
            );
            this.registrationError = true;
          }
          this.isLoading = false;
          this.passwordSubmitted = true;
          this.changeDetector.detectChanges();
        },
        (error) => {
          logger.debug('Accounts submit error', error);
          this.registerError();
        }
      );
  }

  /**
   * get Register Token for accessing the register API
   */
  private getRegisterToken(): void {
    this.isLoading = true;
    this.fastTrackRegistrationService
      .getTokenToAccessRegister$(this.uSServicingPersonalData)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        ([userIdResponse, expressNumberResponse]) => {
          if (
            userIdResponse?.body?.result?.status ||
            expressNumberResponse?.body?.result?.status
          ) {
            logger.error(
              // error so reported to datadog
              'User Id or Express Number is Already Registered: ' +
                this.uSServicingPersonalData.identifiers.expressNumber
            );
            this.modalConfig.title = this.translateService.instant(
              'signin.add-password-title-error'
            );
            this.registerUsrSysno = userIdResponse?.body?.result?.usersysno;
            this.registerToken = userIdResponse?.headers?.get('Authorization');
            if (
              userIdResponse?.body?.result?.userStatus ===
              AccessRegisterStatus.PARTIAL_REG
            ) {
              this.showResendEmail = true;
            } else if (
              userIdResponse?.body?.result?.userStatus ===
              AccessRegisterStatus.DELETED
            ) {
              logger.error(
                'Deleted user with express number: ' +
                  this.uSServicingPersonalData.identifiers.expressNumber
              );
              this.showDeletedMessage = true;
            } else {
              this.isAlreadyRegisteredError = true;
            }
            this.passwordSubmitted = true;
            this.isLoading = false;
            this.changeDetector.detectChanges();
          } else {
            logger.debug('User Id/Express Number is not Registered');
            this.registerToken = userIdResponse.headers.get('Authorization');
            this.savePasswordProcess(this.registerToken);
          }
        },
        (error) => {
          logger.error('getRegisterToken AP error', error);
          this.registerError();
        }
      );
  }

  /**
   * Resend Email Process
   */
  private resendEmailProcess(): void {
    // Placeholder for resend password through API
    logger.debug('Resend password confirmation email');
    this.isLoading = true;
    this.fastTrackRegistrationService
      .resendActivationEmail$(this.registerUsrSysno, this.registerToken)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (response: ResendConfResponse) => {
          logger.debug('resendEmailProcess API response', response);
          if (response?.body?.isSuccess) {
            logger.debug('resendEmailProcess API Success');
            this.modalConfig.title = this.translateService.instant(
              'signin.add-password-resend-confirmation-email'
            );
            this.passwordSubmitted = true;
            this.isResentEmailSuccess = true;
            this.isLoading = false;
            this.changeDetector.detectChanges();
          } else {
            logger.error(
              'resendEmailProcess API isSuccess',
              response?.body?.isSuccess
            );
            this.registerError();
          }
          this.showResendEmail = false;
        },
        (error) => {
          logger.error('resendEmailProcess API error', error);
          this.isAlreadyRegisteredError = false;
          this.registerError();
          this.showResendEmail = false;
        }
      );
  }

  /**
   * Resetting password form
   */
  private resetPassForm(): void {
    this.passwordSubmitted = false;
    this.registrationError = false;
    this.isAlreadyRegisteredError = false;
    this.isResentEmailSuccess = false;
    this.showResendEmail = false;
    this.submitDisabled = true;
    this.passForm.reset();
    this.modalConfig.title = this.translateService.instant(
      'signin.add-password-title'
    );
  }

  /**
   * Registration Error
   */
  private registerError(): void {
    this.modalConfig.title = this.translateService.instant(
      'signin.add-password-title-error'
    );
    this.registrationError = true;
    this.passwordSubmitted = true;
    this.isLoading = false;
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
