<eds-simple-modal
  [config]="modalConfig"
  [modalId]="'addPasswordModal'"
  [showModal]="isModalVisible$ | async"
  [showClose]="true"
  [disableCloseOnBackground]="true"
  (onClose)="onModalClose()"
>
  <div modal-body>
    <div *ngIf="!passwordSubmitted">
      <p>{{ "signin.add-password-create-password-txt" | translate }}</p>
      <eds-panel>
        <div edsSpacing="sm">
          <eds-text-input
            id="add-password-email"
            name="email"
            class="grid container us-servicing-registration-modal__input us-servicing-registration-modal--disabled"
            [value]="emailValue"
            [label]="'signin.add-password-id-email' | translate"
            [required]="false"
            [hasHelperText]="false"
            [hasMaskedIcon]="false"
            [type]="'text'"
            [readOnly]="true"
          ></eds-text-input>
        </div>
        <div edsSpacing="sm">
          <eds-text-input
            id="add-password-password"
            name="password"
            value=""
            class="grid container us-servicing-registration-modal__input"
            [label]="'signin.password-label' | translate"
            [required]="true"
            [maxValue]="70"
            [invalidText]="invalidPasswordText"
            [hasHelperText]="false"
            [invalid]="invalidPassword"
            [hasMaskedIcon]="true"
            [type]="passwordType"
            [showMaskedText]="'signin.show' | translate"
            [hideMaskedText]="'signin.hide' | translate"
            (maskedIconClicked)="onMaskedIconClicked($event)"
            (inputTextChange)="onInputTextChange($event)"
            (focusin)="onInputFocus()"
            (focusout)="outInputFocus()"
            edsSpacing="sm"
          ></eds-text-input>
        </div>
        <div *ngIf="showPasswordRules">
          <div
            class="us-servicing-registration-modal__pass-rules"
            [innerHTML]="'signin.add-password-pass-rules' | translate"
          ></div>
          <div
            class="us-servicing-registration-modal__pass-rules"
            [class.us-servicing-registration-modal__pass-rule--passed]="
              validPassRules.rule1
            "
            [innerHTML]="'signin.add-password-pass-rule1' | translate"
          ></div>
          <div
            class="us-servicing-registration-modal__pass-rules"
            [class.us-servicing-registration-modal__pass-rule--passed]="
              validPassRules.rule2
            "
            [innerHTML]="'signin.add-password-pass-rule2' | translate"
          ></div>
          <div
            class="us-servicing-registration-modal__pass-rules"
            [class.us-servicing-registration-modal__pass-rule--passed]="
              validPassRules.rule3
            "
            [innerHTML]="'signin.add-password-pass-rule3' | translate"
          ></div>
          <div
            class="us-servicing-registration-modal__pass-rules"
            [class.us-servicing-registration-modal__pass-rule--passed]="
              validPassRules.rule4
            "
            [innerHTML]="'signin.add-password-pass-rule4' | translate"
            edsSpacing="sm"
          ></div>
        </div>
        <eds-panel theme="grey" edsSpacing="lg">
          <div edsPadding edsPaddingAll="md">
            <div edsFlexbox alignItems="center">
              <eds-checkbox
                [hideLabel]="true"
                [inline]="true"
                [required]="true"
                [labelName]="'signin.add-password-tc-checkbox' | translate"
                (checkedChange)="onCheckedChange($event)"
              ></eds-checkbox>
              <p
                edsPadding
                edsPaddingBottom="none"
                edsSpacing="none"
                [innerHTML]="
                  'signin.add-password-tc-link' | translate | safeHtml
                "
              ></p>
            </div>

            <div
              edsCol
              [columnNumbers]="{ l: 11, m: 11, s: 11 }"
              [innerHTML]="
                'signin.add-password-tc-description' | translate | safeHtml
              "
            ></div>
          </div>
        </eds-panel>
        <div
          *ngIf="tcCheckboxError"
          class="us-servicing-registration-modal__validation-text us-servicing-registration-modal__validation-text__tc-checkbox"
          [innerHTML]="
            'signin.add-password-tc-checkbox-error' | translate | safeHtml
          "
        ></div>
        <button
          id="btnAddPassword"
          class="ft__btn"
          edsButton="primary"
          size="normal"
          [noMargin]="true"
          [isDisabled]="submitDisabled"
          [disabled]="submitDisabled"
          (click)="submitPassword($event)"
        >
          <span class="ft__btn__txt" *ngIf="!isLoading">{{
            "signin.submit" | translate
          }}</span>
          <span
            class="ft__btn__txt us-servicing-registration-modal__loader"
            *ngIf="isLoading"
          >
            <ngx-spinner
              name="submitPasswordSpinner"
              bdColor="rgba(0,0,0,0)"
              size="small"
              color="#000"
              type="line-scale-party"
              [fullScreen]="false"
            >
            </ngx-spinner>
          </span>
        </button>
      </eds-panel>
    </div>
    <div *ngIf="passwordSubmitted">
      <div
        edsSpacing="md"
        *ngIf="
          !registrationError &&
          !isAlreadyRegisteredError &&
          !isResentEmailSuccess
        "
      >
        {{ "signin.add-password-submit-confirmation-txt" | translate }}
      </div>
      <div edsSpacing="md" *ngIf="registrationError">
        {{ "signin.add-password-submit-confirmation-error" | translate }}
      </div>
      <div edsSpacing="md" *ngIf="showDeletedMessage">
        <p>{{ "signin.add-password-deleted-user" | translate }}</p>
      </div>
      <div edsSpacing="md" *ngIf="isAlreadyRegisteredError">
        <p>{{ "signin.add-password-email-already-exist-error" | translate }}</p>
        <p>
          {{ "signin.add-password-email-error-resend-email" | translate }}
          <eds-link-item
            class="us-servicing-registration-modal__resend-email-link"
            [title]="
              'signin.add-password-resend-confirmation-email' | translate
            "
            [analyticsNoEvent]="true"
            [stopNavigation]="true"
            [hasTarget]="false"
            (onLinkClick)="resendEmail($event)"
          ></eds-link-item>
        </p>
      </div>
      <div edsSpacing="md" *ngIf="isResentEmailSuccess">
        {{ "signin.add-password-resend-email-success" | translate }}
      </div>
      <div>
        <button
          *ngIf="showResendEmail"
          id="btnResendEmail"
          class="ft__btn"
          edsButton="secondary"
          size="normal"
          [noMargin]="true"
          [isDisabled]="false"
          (click)="resendEmail($event)"
        >
          <span class="ft__btn__txt" *ngIf="!isLoading">{{
            "signin.add-password-resend-confirmation-email" | translate
          }}</span>

          <span
            class="ft__btn__txt us-servicing-registration-modal__loader"
            *ngIf="isLoading"
          >
            <ngx-spinner
              name="submitPasswordSpinner"
              bdColor="rgba(0,0,0,0)"
              size="small"
              color="#000"
              type="line-scale-party"
              [fullScreen]="false"
            >
            </ngx-spinner>
          </span>
        </button>
        <button
          id="btnClose"
          class="ft__btn us-servicing-registration-modal__btn-close"
          edsButton="primary"
          size="normal"
          [noMargin]="true"
          [isDisabled]="false"
          (click)="onModalClose()"
        >
          <span class="ft__btn__txt">{{ "signin.close" | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div modal-footer class="text-align-left"></div>
</eds-simple-modal>
