<div *ngIf="siteAlerts">
  <div edsGrid *ngFor="let siteAlert of siteAlerts">
    <div class="container--wide">
      <div edsRow edsSpacing edsPadding edsPaddingTop="lg">
        <div edsCol>
          <eds-notifications
            [hasBorders]="true"
            [hasBorderRadius]="false"
            [hasShadows]="false"
            [hasIcon]="true"
            [inline]="false"
            [title]="siteAlert.notificationDetails.title"
            [CTAText]="siteAlert.notificationDetails.ctaText"
            [body]="siteAlert.notificationDetails.notificationLabel"
            [notificationType]="siteAlert.notificationDetails.notificationType"
            [canClose]="siteAlert.notificationDetails.canClose"
            [primaryBtnText]="
              siteAlert.notificationDetails.primaryBtnData.displayText
            "
            [notificationOptions]="
              siteAlert.notificationDetails.notificationButton
            "
            [secondaryBtnText]="
              siteAlert.notificationDetails.secondaryBtnData.displayText
            "
            [tertiaryBtnText]="
              siteAlert.notificationDetails.tertiaryBtnData.displayText
            "
            (btnSubscribe)="processClick($event, siteAlert)"
            (closeEvent)="closeSiteAlert(siteAlert.notificationDetails)"
            [signInTooltip]="'common.locked-link-tooltip' | translate"
            [loggedIn]="isLoggedIn"
          >
          </eds-notifications>
        </div>
      </div>
    </div>
  </div>
</div>
