import { CurrencyCode } from '@types';

// TODO: this should be moved to EDS
export type NAVChangeDirection = 'gain' | 'static' | 'loss';

export interface NAV {
  currencyCode?: CurrencyCode;
  navAsOfDate?: string;
  navValue?: string;
  navChangeValue?: string;
  navChangePercentage?: string;
  popValue?: string;
  navYearToDateReturn?: string;
  marketPriceAsOfDate?: string;
  marketPriceValue?: string;
  marketPriceChangeValue?: string;
  marketPriceChangePercentage?: string;
  marketPriceYearToDateReturn?: string;
  marketToMarketNav?: string;
  marketToMarketNavChg?: string;
  dailyVolume?: string;
  distributionRateAtNAV?: string;
  distributionRateAtPOP?: string;
  highestNavValueIn52Weeks?: string;
  highestNavValueDateIn52Weeks?: string;
  lowestNavValueIn52Weeks?: string;
  lowestNavValueDateIn52Weeks?: string;
  highestMarketPriceValueIn52Weeks?: string;
  highestMarketPriceValueDateIn52Weeks?: string;
  lowestMarketPriceValueIn52Weeks?: string;
  lowestMarketPriceValueDateIn52Weeks?: string;
  highestNavValueDateAsYearToDate?: string;
  lowestNavValueDateAsYearToDate?: string;
  highestNavValueAsYearToDate?: string;
  lowestNavValueAsYearToDate?: string;
  highestNavValue1YearBack?: string;
  highestNavValue2YearBack?: string;
  highestNavValue3YearBack?: string;
  highestNavValue4YearBack?: string;
  highestNavValue5YearBack?: string;
  highestNavValue6YearBack?: string;
  highestNavValue7YearBack?: string;
  highestNavValue8YearBack?: string;
  highestNavValue9YearBack?: string;
  lowestNavValue1YearBack?: string;
  lowestNavValue2YearBack?: string;
  lowestNavValue3YearBack?: string;
  lowestNavValue4YearBack?: string;
  lowestNavValue5YearBack?: string;
  lowestNavValue6YearBack?: string;
  lowestNavValue7YearBack?: string;
  lowestNavValue8YearBack?: string;
  lowestNavValue9YearBack?: string;
  highestNavValueDate1YearBack?: string;
  lowestNavValueDate1YearBack?: string;
  highestNavValueDate2YearBack?: string;
  lowestNavValueDate2YearBack?: string;
  highestNavValueDate3YearBack?: string;
  lowestNavValueDate3YearBack?: string;
  highestNavValueDate4YearBack?: string;
  lowestNavValueDate4YearBack?: string;
  highestNavValueDate5YearBack?: string;
  lowestNavValueDate5YearBack?: string;
  highestNavValueDate6YearBack?: string;
  lowestNavValueDate6YearBack?: string;
  highestNavValueDate7YearBack?: string;
  lowestNavValueDate7YearBack?: string;
  highestNavValueDate8YearBack?: string;
  lowestNavValueDate8YearBack?: string;
  highestNavValueDate9YearBack?: string;
  lowestNavValueDate9YearBack?: string;
  moneyFundNav?: string;
  moneyFundNavChangeValue?: string;
  moneyFundMarketNav?: string;
  masterFundNav?: string;
  dailyLiquidAssetRatio?: string;
  weeklyLiquidAssetRatio?: string;
  intradayNavDate?: string;
  intradayNav?: string;
  medianBidAskSpread?: string;
  netShareHolderValue?: string;
  bidAskSpread?: string;
  premiumDiscountAvg?: string;
  premiumDiscountPct?: string;
  changeDirection?: NAVChangeDirection;
  redemptionPrice?: string;
  applicationPrice?: string;
  moneyFundAsOfDate?: string;
}
