import { PersonalisationToken } from '@types';

export const AUTHORIZATION = 'Authorization';
export const BYPASS = 'bypass';
export const ROLE = 'role';
export const CRAWLERROLE = 'cr';
export const OAUTH = 'OAuth';
export const OAUTH_TOKEN = 'OAUTH_TOKEN_COOKIE';
export const GENERIC_ID_TOKEN: PersonalisationToken = 'Elr1ynj5aUrUjxlWqHfvJfNkK0geIzTfZhPfjCNEWYCmGA24beCrne5oBsRwzNR0BjAhuOVZcQw0J014Aucm0xqA/h7Fnaw2linsQmK5ML8hMT3ILbuuFKKd3jr89ZMo+Qu8oWux6zRyRgnO713e6vr96E8tgJH6gsWr+OdBGPM=' as PersonalisationToken;
export const ENDPOINT = {
  validateSession: { link: '/validateSession', errorCode: 'APIINT001' },
  getProfile: { link: '/profileData', errorCode: 'APIINT002' },
  authenticate: { link: '/loginprofile/authenticate', errorCode: 'APIINT003' },
  signOut: { link: '/logout?logout=true', errorCode: 'APIINT004' },
  upgradeAccess: { link: '/updateAccess', errorCode: 'APIINT005' },
};

export const UPGRADE_ACCESS_ROUTE = '/profile/signin/upgrade/access';
export const SH_DASHBIARD = '/advisor/dashboard';
export const FP_DASHBOARD = '/investor/dashboard';
export const NEW_ACCOUNT = '/forms/acctType';
export const LOGOUT = 'LOGOUT';
export const VALID = 'VALID';
export const EXPIRED = 'EXPIRED';
export const INVALID = 'INVALID';
export const LOGOUT_TRUE = 'logout=true';
export const LOGOUT_TYPE_M = '&logoutType=M';
export const SIGNIN_USERID = 'userId';
export const SIGNIN_PIN = 'pin';
export const SIGNIN_REMEMBERME = 'rememberMe';
export const SIGNIN_REDIRECTTOURL = 'redirectToURL';
export const NEW_AUTH_SESSION = 'newAuthSession';
export const LOCATION_LABL = 'Location';
export const TRUE = 'true';
export const FALSE = 'false';
export const MANUAL_LOGOUT = 'M';
export const AUTO_LOGOUT = 'A';
export const PWD_LOGGED_LOGOUT = 'LP';
export const SECURITY_LOGGED_LOGOUT = 'LS';
export const MOS_PWD_LOGGED_LOGOUT = 'LPM';
export const MOS_SECURITY_LOGGED_LOGOUT = 'LSM';
export const LOGGED_LOGOUT = 'L';
export const MANUAL_LOGOUT_LABEL = 'manual-logout';
export const AUTO_LOGOUT_LABEL = 'auto-logout';
export const PWD_LOGGED_LOGOUT_LABEL = 'password-fail-logout';
export const SECURITY_LOGGED_LOGOUT_LABEL = 'sec-question-fail-logout';
export const MOS_PWD_LOGGED_LOGOUT_LABEL = 'mos-password-fail-logout';
export const MOS_SECURITY_LOGGED_LOGOUT_LABEL = 'mos-sec-question-fail-logout';
export const MANUAL_LOGOUT_NO_ACCOUNTS = 'manual-no-accounts-logout';
export const LOGOUT_TITLE = 'logout-title';
export const SESSION_EXPIRED = 'session-expired-title';
export const REAUTHENTICATION = 'security-re-authentication-title';
export const UPGRADE_TITLE = 'upgrade-title';
export const API_TYPE = 'api-type';
export const API_ERROR = 'api-error';
export const API_ERROR_TITLE = 'api-error-title';
export const INV = 'INV';
export const INST = 'INST';
export const INT = 'INT';
export const DC = 'DC';
export const FP = 'FP';
export const FA = 'FA';
export const RIA = 'RIA';
export const SH = 'SH';
export const GK = 'GK';
export const NEVER = 'never';
export const UPGRADE_FLAG = 'upgrade';
export const FPGROUP = 'FPGROUP';
export const NEVER_FLAG = 'N';
export const MY_CLIENTS = 'My Clients';
export const RECENT_TRANSACTIONS = 'Recent Transactions';
export const STATEMENTS = 'Statements';
export const TAX_DOCUMENTS = 'Tax Documents';
export const UPGRADE_BTN = 'Upgrade';
export const NOTNOW_BTN = 'Not Now';
export const NEVER_BTN = 'Never';
export const NEW_ACCOUNT_BTN = 'NewAccount';
export const OPEN_BTN = 'Open';
export const UPGRADE_NOTNOW = 'upgradeNotnow';
export const BLOCK_DASHBOARD_SYSTEM = 'block-dashboard-system';
export const BLOCK_DASHBOARD_USER = 'block-dashboard-user';
export const ACCOUNTS_OFFLINE_FP = 'accounts-offline-fp';
export const ACCOUNTS_OFFLINE_SH = 'accounts-offline-sh';
export const NO_ACCESS_SH = 'no-access-sh';
export const NO_ACCESS_FP = 'no-access-fp';
export const UPGRADE_FORM_FP = 'upgrade-form-fp';
export const UPGRADE_FORM_SH = 'upgrade-form-sh';
export const UPGRADE_MESSAGE_FP = 'upgraded-fp';
export const SEGMENT_COOKIE = 'user_role';
export const USER_FIRM_COOKIE = 'user_firm_channel';
export const USER_TYPE_COOKIE = 'userType';
export const FIRM_PARTNER_COOKIE = 'firm_partner';
export const FIRM_TOKEN = 'firm_token';
export const PROFILE_COOKIE = 'ftcom0';
export const ACCOUNTS_DASHBOARD = 'Account Dashboard';
export const DONT_REMIND = 'dont-remind-me';
export const OPERATION_FAILED = 'Operation Failed';
export const QUARTERLY_COMMENTARY = 'QUARTERLY-COMMENTARY';
export const MONTHLY_COMMENTARY = 'MONTHLY-COMMENTARY';
export const FUND_COMMENTARY = 'FUND-COMMENTARY';
export const SEGMENT_SWITCH = 'segment-switch';
export const TOKEN_EXPIRY = 'isTokenExpired';
export const MEDIUM_DATE_FORMAT = 'MMM d, y';
export const LONG_DATE_FORMAT = 'MMMM d, y';
export const MARKETING_FLAG = 'marketingFlag';
export const NEW = 'NEW';
export const DEFAULT_IMAGE_SRC = 'assets/images/ben_head.png';
export const USER_GROUP = 'userGroup';
export const MRK_CONTENT = 'MRK_CONTENT';
export const ANALYTICS_DATE_FORMAT = 'MM/DD/YY';
export const ANALYTICS_DATE_EVENT = 'date_selection';
export const ANALYTICS_DETAILED_DATE_EVENT = 'Calendar Date Selection';
export const ANALTICS_PAGINATION_EVENT = 'pagination';
export const HEADER_LOGO = '/assets/images/logos/FT_logo_pos_RGB@2x.png';
export const ANGULAR_HOOK = 'Angular Hook';
export const MYFUNDS_HOOK = 'MYFUNDS';
export const REMEMBER_ME_YES = 'Y';
export const REMEMBER_ME_NO = 'N';
export const ROLE_REDIRECT = 'role=';
export const SEGMENT_TYPE = 'segmentType=';
export const EXTERNAL = 'E';
export const MONTH_YEAR_DATE_FORMAT = 'MMMM y';
export const COMMENTARY = 'COMMENTARY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const SIGNIN_SPINNER = 'signinSpinner';
export const SR_ADVISOR_CONSULTANT = 'Sr. Advisor Consultant';
export const ADVISOR_CONSULTANT = 'Advisor Consultant';
export const PCS_ACCEPT_FLAG = 'pcsAcceptFlag';
export const PEER_RANKINGS_KEY = 'RANKINGS';
export const IS_MFA_COMPLETE = 'IS_MFA_COMPLETE';
export const TEMP_USER_COOKIE = 'TEMP_USER_COOKIE';
export const DEVICE_RECOGNIZE_STATUS = 'device_recognize';
export const USER_SYS_NUMBER = 'user_sys_no';
export const SECURITY_QUESTION_STATUS = 'security_question_yn';
export const SECURITY_QUESTION_AVAILABLE = 'security_question_available_yn';
export const AUTH_RESPONSE = 'authResponse';
export const EMAIL_VALIDATOR = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
export const VIEW_ACCOUNTS_DASHBOARD = 'View Accounts Dashboard';
export const DATE_FORMAT_YEAR_MONTH_STD = 'YYYY-MM';
export const DATE_FORMAT_STD = 'YYYY-MM-DD';
export const EIGHT_COLUMN_CONTAINER = 'container(8 columns)';
export const STATIC_PASSWORD_COOKIE = 'static-pass';
export const STRATEGY_PERFORMANCE_SMA = 'sma';
// TODO : if we have multiple experiments we can add the metrics on the same place
export const LAUNCH_DARKLY_METRICS_NAME = {
  PAT_BUTTON_CLICK: 'PAT_Button_Click',
};

export const CUSTOM_DATE_CHANGE = 'custom-date-change';
export const BREAK = 'break';
