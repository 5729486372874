import { Identifiers, Product } from '@models';
import { BaseFilter } from '@products/fund-listing/services/fund-filter.service';
import { FilterType, OptionWithCount, ShareClassCode } from '@types';
import {
  KeyIdentifiers,
  LiteratureDocument,
} from './model/literature-document';
import { GlobalRegulatoryShareclassInfo } from '@literature/literature-global-regulatory-listing/literature-global-regulatory-listing.component';

export interface SelectedFilters {
  label: string;
  value: string;
}

export interface SelectedFiltersArray {
  selectedFilters: SelectedFilters[];
}
export type DropDownMultiSelectItem = OptionWithCount & {
  checked: boolean;
};

export type DropDownMultiSelect = FilterType & {
  preSelectedValues: OptionWithCount[];
};

export interface LitCardSection {
  colId: string;
  label: string;
  val: string;
}
export interface RendererData {
  data?: LiteratureDocumentListingData;
}

export interface LiteraturePageLayoutConfig {
  pageLayoutType?: string;
  pageType: string;
}

export interface LiteratureDocumentListingData {
  literatureData?: LiteratureDocument;
  product?: Product;
}

export interface CustomLiteratureDocumentListingData {
  // TODO: set CustomLiteratureDocument type
  literatureData?: any; // CustomLiteratureDocument;
  product?: Product;
  identifier?: KeyIdentifiers;
  shareClass?: string[];
}

export interface LiteratureListingConfiguration {
  name?: string;
  pageTypeName?: string;
  segmentOverrideList?: string[];
  additionalFilters?: string[];
  primaryFilters?: string[];
  documentTypeDisplay?: string[];
  literatureListGridColumn?: string[];
  documentTypeDisplayForLoggedIn?: string[];
  languageFilterArray?: string[];
  activeList?: string[];
  fundOverrideFlag?: boolean;
}

export interface ShareClassBasedDocs {
  [key: string]: DctermtypeBasedDocs;
}

export interface DctermtypeBasedDocs {
  [key: string]: LiteratureDocument;
}
export interface ShareclassLevelLiteratureDocumentListingData {
  literatureData?: object; // TODO : Define type of literature Data
  product?: Product;
  primaryShareclassCode?: ShareClassCode;
  shareclassOption?: GlobalRegulatoryShareclassInfo[];
}

export interface CookieItems {
  items?: string[];
}
export interface LiteratureDocumentListingState {
  data?:
    | LiteratureDocumentListingData[]
    | CustomLiteratureDocumentListingData[]
    | ShareclassLevelLiteratureDocumentListingData[];
  datatab?: Map<string, LiteratureDocumentListingData[]>;
  isError?: boolean;
  cartItems?: LiteratureDocument[];
  cartAction?: string;
}

export type IdentifierKey = keyof Identifiers;

export interface ExcelDocType {
  columnHeader: IdentifierKey | string;
  columnValue: string;
}

export enum SelectNotifications {
  ALL = 'all',
  CURRENT = 'current',
}
