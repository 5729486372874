import { Injectable } from '@angular/core';
import { LiteratureDocument } from '@types';

@Injectable({ providedIn: 'root' })
export class LiteratureRulesHelperService {
  /**
   *
   * @param data literature document
   * set the metaData based on rules (metadata info)
   * allow show/hide different alert msg on listing/detail/fundDocuments/cart modal/cart page
   * global used function to control some rules
   */
  public checkLiteratureRules(data: LiteratureDocument): void {
    /**
     * DCE-2160 - Issue with US Documents Order When (Is Order Itself) = No
     */
    if (!data.clientUse || data.isInv === 'N' || data.isOrdItself === 'N') {
      if (data?.mandatoryMaterial?.length > 0) {
        /**
         * If isOrdItself is No and data.mandatoryMaterial.length is greate then zero than data.hardCopyNoAvailable should be true
         */
        data.hardCopyNotAvailable = false;
      } else {
        /**
         * If data.mandatoryMaterial.length is euqal to Zero then data.hardCopyNotAvailable should be false
         */
        data.hardCopyNotAvailable = true;
      }
    }
    if (data.mandatoryMaterial?.length > 0) {
      data.isMandatoryMaterial = true;
    }
  }
}
