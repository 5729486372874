<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div edsGrid>
      <div edsRow>
        <div edsCol>
          <eds-section-header
            [level]="3"
            size="h3"
            theme="basic"
            [asOfLabel]="'products.as-at' | translate"
            [asOfDate]="state?.asOfDate"
            caveatPlacement="RiskStatistics"
            [analyticsName]="
              'products.performance-risk-measures' | translate: brConfig?.fundId
            "
            [tooltip]="tooltips['products.risk-measures-section']"
            edsSpacing="sm"
            >{{
              "products.performance-risk-measures" | translate: brConfig?.fundId
            }}</eds-section-header
          >
          <ng-container *ngIf="performanceStatusCaveat">
            <eds-proximal
              edsSpacing="lg"
              edsPadding
              edsPaddingBottom="sm"
              [placement]="performanceStatusCaveat"
            ></eds-proximal>
            <br />
          </ng-container>
          <ng-container *ngIf="state?.statistics?.length > 0">
            <eds-proximal placement="RiskStatisticsProximalTop"></eds-proximal>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="!isBrazilSpecificLayout; else layoutForBrazil">
        <ng-container *ngIf="isPopulated; else showNoDataMessage">
          <div
            edsRow
            edsPadding
            edsPaddingBottom="sm"
            *ngIf="state?.benchmarkName && !state.hideRiskStatisticsBenchmark"
          >
            <div edsCol>
              {{ "products.performance-risk-measures-benchmark" | translate }}
              {{ state?.benchmarkName }}
            </div>
          </div>
          <div edsRow>
            <div edsCol *ngIf="state?.statistics?.length > 0">
              <table class="table">
                <thead class="table__thead">
                  <tr class="table__tr">
                    <th class="table__th table__th--middle">
                      {{ "products.statistics" | translate }}
                      <button
                        *ngIf="tooltips['products.risk-measures-statistics']"
                        edsTooltip
                        tooltipTheme="dark"
                        [analyticsName]="'products.statistics' | translate"
                        [tooltipContent]="
                          tooltips['products.risk-measures-statistics']
                        "
                        [tooltipAriaLabel]="'products.statistics' | translate"
                        [tooltipButton]="true"
                      >
                        <eds-icon
                          edsPadding
                          edsPaddingLeft="xs"
                          type="info"
                          size="small"
                        ></eds-icon>
                      </button>
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ "products.price-toggle-1-year" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-3-years" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-5-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ "products.price-toggle-10-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ "products.price-toggle-since-inception" | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__tr"
                    *ngFor="let rowData of state?.statistics"
                  >
                    <td class="table__td">
                      {{ rowData.rmName
                      }}<eds-footnote
                        [placement]="'Risk Statistics:' + rowData.footnote"
                      ></eds-footnote>
                      <button
                        *ngIf="tooltips[rowData.tooltip]"
                        edsTooltip
                        [analyticsName]="rowData.rmName"
                        [tooltipContent]="tooltips[rowData.tooltip]"
                        [tooltipAriaLabel]="rowData.rmName"
                        [tooltipButton]="true"
                      >
                        <eds-icon
                          edsPadding
                          edsPaddingLeft="xs"
                          type="info"
                          size="small"
                        ></eds-icon>
                      </button>
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ rowData.year1Val }}
                    </td>
                    <td class="table__td">{{ rowData.year3Val }}</td>
                    <td class="table__td">{{ rowData.year5Val }}</td>
                    <td
                      class="table__td"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ rowData.year10Val }}
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ rowData.valueSinceInception }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              edsCol
              *ngIf="
                state?.stdDev?.length > 0 || state?.sharpeRatio?.length > 0
              "
            >
              <table
                class="table"
                *ngIf="state?.stdDev?.length > 0"
                edsSpacing="xl"
              >
                <thead class="table__thead">
                  <tr class="table__tr">
                    <th class="table__th table__th--middle">
                      {{ "products.stdDev" | translate
                      }}<eds-footnote
                        placement="Risk Statistics:performance-STANDARD_DEVIATION_FUND-risk-statistic"
                      >
                      </eds-footnote>
                      <button
                        *ngIf="
                          tooltips['products.risk-measures-standard-deviation']
                        "
                        edsTooltip
                        tooltipTheme="dark"
                        [analyticsName]="'products.stdDev' | translate"
                        [tooltipContent]="
                          tooltips['products.risk-measures-standard-deviation']
                        "
                        [tooltipAriaLabel]="'products.stdDev' | translate"
                        [tooltipButton]="true"
                      >
                        <eds-icon
                          edsPadding
                          edsPaddingLeft="xs"
                          type="info"
                          size="small"
                        ></eds-icon>
                      </button>
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ "products.price-toggle-1-year" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-3-years" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-5-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ "products.price-toggle-10-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ "products.price-toggle-since-inception" | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__tr"
                    *ngFor="let rowData of state?.stdDev; let i = index"
                  >
                    <td class="table__td">
                      {{ rowData.rmName }}
                      <span *ngIf="rowData.calcTypeLabel">
                        {{ rowData.calcTypeLabel | translate }}</span
                      >
                      <eds-footnote
                        *ngIf="i === 1"
                        placement="Risk Statistics:PrimaryPlacementRiskStatistics"
                      ></eds-footnote>
                      <eds-footnote
                        *ngIf="i === 2"
                        placement="Risk Statistics:SecondaryPlacementRiskStatistics"
                      ></eds-footnote>
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ rowData.year1Val }}
                    </td>
                    <td class="table__td">{{ rowData.year3Val }}</td>
                    <td class="table__td">{{ rowData.year5Val }}</td>
                    <td
                      class="table__td"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ rowData.year10Val }}
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ rowData.valueSinceInception }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table" *ngIf="state?.sharpeRatio?.length > 0">
                <thead class="table__thead">
                  <tr class="table__tr">
                    <th class="table__th table__th--middle">
                      {{ "products.sharpe-ratio" | translate
                      }}<eds-footnote
                        placement="Risk Statistics:SHARPE_RATIO-RiskStatistic"
                      ></eds-footnote>
                      <button
                        *ngIf="tooltips['products.risk-measures-sharpe-ratio']"
                        edsTooltip
                        tooltipTheme="dark"
                        [analyticsName]="'products.sharpe-ratio' | translate"
                        [tooltipContent]="
                          tooltips['products.risk-measures-sharpe-ratio']
                        "
                        [tooltipAriaLabel]="'products.sharpe-ratio' | translate"
                        [tooltipButton]="true"
                      >
                        <eds-icon
                          edsPadding
                          edsPaddingLeft="xs"
                          type="info"
                          size="small"
                        ></eds-icon>
                      </button>
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ "products.price-toggle-1-year" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-3-years" | translate }}
                    </th>
                    <th class="table__th table__th--middle text-no-wrap">
                      {{ "products.price-toggle-5-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ "products.price-toggle-10-years" | translate }}
                    </th>
                    <th
                      class="table__th table__th--middle text-no-wrap"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ "products.price-toggle-since-inception" | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__tr"
                    *ngFor="let rowData of state?.sharpeRatio; let i = index"
                  >
                    <td class="table__td">
                      {{ rowData.rmName }}
                      <span *ngIf="rowData.calcTypeLabel">
                        {{ rowData.calcTypeLabel | translate }}</span
                      >
                      <eds-footnote
                        *ngIf="i === 1"
                        placement="Risk Statistics:PrimaryPlacementRiskStatistics"
                      ></eds-footnote>
                      <eds-footnote
                        *ngIf="i === 2"
                        placement="Risk Statistics:SecondaryPlacementRiskStatistics"
                      ></eds-footnote>
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.show1YrPerformance"
                    >
                      {{ rowData.year1Val }}
                    </td>
                    <td class="table__td">{{ rowData.year3Val }}</td>
                    <td class="table__td">{{ rowData.year5Val }}</td>
                    <td
                      class="table__td"
                      *ngIf="state.config.isFundOlderThan10Yrs"
                    >
                      {{ rowData.year10Val }}
                    </td>
                    <td
                      class="table__td"
                      *ngIf="state.config.showSinceInception"
                    >
                      {{ rowData.valueSinceInception }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div edsRow>
        <div edsCol *ngIf="state?.statistics?.length > 0">
          <eds-proximal placement="RiskStatisticsProximalBottom"></eds-proximal>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #showNoDataMessage>
  <span>{{ "products.performance-no-data-risk-statistics" | translate }} </span>
</ng-template>
<ng-template #layoutForBrazil>
  <ng-container
    *ngIf="
      (!riskEfficiencyAnalysisState?.isError &&
        riskEfficiencyAnalysisState?.efficiencyAnalysis?.length) ||
        state?.statistics?.length;
      else showNoDataMessage
    "
  >
    <div edsRow edsPadding edsPaddingBottom="sm" *ngIf="state?.benchmarkName">
      <div edsCol>
        {{ "products.performance-risk-measures-benchmark" | translate }}
        {{ state?.benchmarkName }}
      </div>
    </div>
    <div edsSubGrid="layout-1">
      <ft-efficiency-analysis
        [state]="riskEfficiencyAnalysisState"
        *ngIf="
          !riskEfficiencyAnalysisState?.isError ||
          riskEfficiencyAnalysisState?.efficiencyAnalysis?.length
        "
      ></ft-efficiency-analysis>
      <div *ngIf="state?.statistics?.length > 0">
        <eds-title edsSpacing="sm" [size]="'h6'"
          >{{ "products.statistical-analysis-table-header" | translate }}
        </eds-title>
        <table class="table">
          <thead class="table__thead">
            <tr class="table__tr">
              <th class="table__th table__th--middle">
                {{ "products.statistics-element-indicator" | translate }}
                <button
                  *ngIf="tooltips['products.risk-measures-statistics']"
                  edsTooltip
                  tooltipTheme="dark"
                  [analyticsName]="'products.statistics' | translate"
                  [tooltipContent]="
                    tooltips['products.risk-measures-statistics']
                  "
                  [tooltipAriaLabel]="'products.statistics' | translate"
                  [tooltipButton]="true"
                >
                  <eds-icon
                    edsPadding
                    edsPaddingLeft="xs"
                    type="info"
                    size="small"
                  ></eds-icon>
                </button>
              </th>
              <th class="table__th table__th--middle text-no-wrap">
                {{ "products.statistics-element-value" | translate }}
              </th>
            </tr>
          </thead>
          <tbody class="table__body">
            <tr class="table__tr" *ngFor="let rowData of state.statistics">
              <td class="table__td">
                {{ rowData.rmName
                }}<eds-footnote
                  [placement]="'Risk Statistics:' + rowData?.footnote"
                ></eds-footnote>
                <button
                  *ngIf="tooltips[rowData.tooltip]"
                  edsTooltip
                  [analyticsName]="rowData?.rmName"
                  [tooltipContent]="tooltips[rowData.tooltip]"
                  [tooltipAriaLabel]="'rowData?.rmName' | translate"
                  [tooltipButton]="true"
                >
                  <eds-icon
                    edsPadding
                    edsPaddingLeft="xs"
                    type="info"
                    size="small"
                  ></eds-icon>
                </button>
              </td>
              <td class="table__td">{{ rowData?.configuredElementValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #previewBlock>
  <h5>Risk Measures Component</h5>
</ng-template>
