export enum CartActionPerformed {
  ADDED_FROM_GRID = 'added from grid',
  ADDED_FROM_STICKY = 'added from sticky',
  DELETED_FROM_CART_MODAL = 'deleted from cart modal',
  ADDED_FROM_ORDER_HISTORY = 'added from order history',
  ADDED_FROM_LITERATURE_DETAIL = 'added from literature detail',
  DELETED_FROM_YOUR_CART = 'deleted from your cart',
  DELETE_ALL_FROM_YOUR_CART = 'deleted all from your cart',
  ADDED_FROM_LITERATURE_DASHBOARD = 'added from literature dashboard',
  ADDED_FROM_MY_LITERATURE = 'added from my literature',
  DELETED_AFTER_ORDER_SUCCESS = 'deleted_after_order_success',
  ADDED_FROM_SEARCH = 'added from search',
  DELETED_FROM_SEARCH = 'deleted from search',
}
