import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import {
  AccessRegisterStatus,
  AppStateService,
  PersonalisationAPIService,
} from '@services';
import {
  PersonalisationPersonalData,
  PersonalisationRegisterAPITokenData,
} from '@types';
import { Logger } from '@utils/logger';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subscriber,
} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

const logger = Logger.getLogger('USServicingService');

export interface AccountsData {
  userGroup: AccountUserGroups;
  usrBusinessKey: string;
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneExtension: string;
  isMobile: AccountsFlag;
  userId: string;
  password: string;
  userLevel: AccountsUserLevel;
  termsAndConditionsFlag: AccountsFlag;
  marketingregRefUrl: string;
  companyName: string;
  dbrInfo: {
    dealerNumber: string;
    branchNumber: string;
    repNumber: string;
  };
  securityQnFlag: AccountsFlag;
}

export interface AccountsApiResponse {
  body?: {
    responseDate?: string;
    result?: {
      usersysno?: string;
      message?: string;
    };
  };
}

export interface ResendConfResponse {
  body?: {
    responseDate?: string;
    isSuccess?: boolean;
    result?: {};
  };
}

export interface RegisterModalData {
  isRegisterSuccess: boolean;
  usrSysNo: string;
}

export interface RegisterUserInfo {
  registerUsrSysNo?: string;
  registerToken?: string;
}

enum AccountUserGroups {
  FP = 'FPGROUP',
  SH = 'SHGROUP',
  FP_MRK = 'FPGROUP_MRK_CONTENT',
  SH_MRK = 'SHGROUP_MRK_CONTENT',
}

enum AccountsUserLevel {
  MARKETING = 3,
  ACCOUNTS = 4,
}

enum AccountsFlag {
  YES = 'Y',
  NO = 'N',
}

@Injectable({
  providedIn: 'root',
})
export class FastTrackRegistrationService {
  public isRegistrationModalVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private registrationModalData$: ReplaySubject<RegisterModalData> = new ReplaySubject<RegisterModalData>(
    1
  );
  private registerUserInfo: RegisterUserInfo = {};
  private resendActivationEmailFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private appStateService: AppStateService,
    private http: HttpClient,
    private personalisationService: PersonalisationAPIService,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    // Constructor placeholder
  }

  /**
   * Get Is Registration Modal Visible - Observable
   */
  public getIsRegistrationModalVisible$(): Observable<boolean> {
    return this.isRegistrationModalVisible$.asObservable();
  }

  /**
   * Set Is Registration Modal Data
   * @param value - boolean
   */
  public setIsRegistrationModalVisible$(value: boolean): void {
    this.isRegistrationModalVisible$.next(value);
  }

  /**
   * Get Is Registration Modal Data - Observable
   */
  public getRegistrationModalData$(): Observable<RegisterModalData> {
    return this.registrationModalData$.asObservable();
  }

  /**
   * Set Is Registration Modal Data
   * @param value - Object
   */
  public setRegistrationModalData$(value: RegisterModalData): void {
    this.registrationModalData$.next(value);
  }

  /**
   * Submit Accounts Password
   * @param uSServicingPersonalData - PersonalisationPersonalData Object
   * @param password - string
   * @param registerToken - string
   */
  public submitAccountsPassword$(
    uSServicingPersonalData: PersonalisationPersonalData,
    password: string,
    registerToken: string
  ): Observable<any> {
    const accountsRegisterApiUrl =
      this.appStateService.getAccountsApiUrl() + '/registerUser';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: registerToken ? `Bearer ${registerToken}` : '',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'If-Modified-Since': '0',
      }),
      withCredentials: true,
      observe: 'response' as 'response',
    };
    const accountData = this.mapAccountsData(uSServicingPersonalData, password);
    return new Observable((observer: Subscriber<any>) => {
      this.http
        .post(accountsRegisterApiUrl, accountData, httpOptions)
        .subscribe(
          (res: any) => {
            observer.next(res);
            observer.complete();
          },
          (error: any) => {
            logger.debug('HTTP request error', error);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Map Accounts Data
   * @param uSServicingPersonalData - PersonalisationPersonalData Object
   * @param password - string
   */
  private mapAccountsData(
    uSServicingPersonalData: PersonalisationPersonalData,
    password: string
  ): AccountsData {
    const marketingregRefUrl = btoa(this.windowRef.location.origin); // Encode origin URL with base64
    return {
      userGroup: AccountUserGroups.FP_MRK,
      usrBusinessKey: uSServicingPersonalData?.identifiers?.expressNumber,
      emailId: uSServicingPersonalData?.email,
      firstName: uSServicingPersonalData?.firstName,
      lastName: uSServicingPersonalData?.lastName,
      phoneExtension: uSServicingPersonalData?.phoneExtension,
      phoneNumber: uSServicingPersonalData?.phoneNumber,
      isMobile: AccountsFlag.NO,
      userId: uSServicingPersonalData?.email,
      password,
      userLevel: AccountsUserLevel.MARKETING,
      termsAndConditionsFlag: AccountsFlag.YES,
      marketingregRefUrl,
      companyName: uSServicingPersonalData.companyName,
      dbrInfo: {
        dealerNumber: uSServicingPersonalData?.dbrInfo?.dealerNumber,
        branchNumber: uSServicingPersonalData?.dbrInfo.branchNumber,
        repNumber: uSServicingPersonalData?.dbrInfo?.repNumber,
      },
      securityQnFlag: AccountsFlag.NO,
    };
  }

  /**
   * get Token To Access Register API
   * @param uSServicingPersonalData - PersonalisationPersonalData Object
   */
  public getTokenToAccessRegister$(
    uSServicingPersonalData: PersonalisationPersonalData
  ): Observable<any> {
    const accountsTokenRegisterApiUrl =
      this.appStateService.getBaseApimUrl() + 'common/profiles/users';
    const httpOptions = {
      headers: new HttpHeaders({
        'X-FT-API-KEY': this.appStateService.getMarketingAuthAPIMKey(),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'If-Modified-Since': '0',
      }),
      observe: 'response' as 'response',
    };
    const uSServicingRegisterAPITokenDataUserId: PersonalisationRegisterAPITokenData = {
      userGroup: AccountUserGroups.FP,
      userId: uSServicingPersonalData?.email,
    };
    const uSServicingRegisterAPITokenDataExpressNo: PersonalisationRegisterAPITokenData = {
      userGroup: AccountUserGroups.FP,
      businessKey: uSServicingPersonalData?.identifiers?.expressNumber,
    };
    return combineLatest([
      this.http.post(
        accountsTokenRegisterApiUrl,
        uSServicingRegisterAPITokenDataUserId,
        httpOptions
      ),
      this.http.post(
        accountsTokenRegisterApiUrl,
        uSServicingRegisterAPITokenDataExpressNo,
        httpOptions
      ),
    ]);
  }

  /**
   * Resend Activation email API
   * @param usrSysNo - string
   * @param registerToken - string
   */
  public resendActivationEmail$(
    usrSysNo: string,
    registerToken: string
  ): Observable<any> {
    const resendActivationEmailApimUrl =
      this.appStateService.getBaseApimUrl() +
      'common/profiles/users/' +
      usrSysNo +
      '/registrationconfirmation';
    const httpOptions = {
      headers: new HttpHeaders({
        'X-FT-API-KEY': this.appStateService.getMarketingAuthAPIMKey(),
        Authorization: registerToken ? `Bearer ${registerToken}` : '',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'If-Modified-Since': '0',
      }),
      observe: 'response' as 'response',
    };
    return new Observable((observer: Subscriber<any>) => {
      this.http.get(resendActivationEmailApimUrl, httpOptions).subscribe(
        (res: any) => {
          observer.next(res);
          observer.complete();
        },
        (error: any) => {
          logger.debug('HTTP resendActivationEmail request error', error);
          observer.error(error);
        }
      );
    });
  }

  /**
   * Set Register User Info
   * @param registerUsrSysNo - RegisterUserInfo UsrSysNo string
   * @param registerToken - RegisterUserInfo registerToken string
   */
  public setRegisterUserInfo(
    registerUsrSysNo: string,
    registerToken: string
  ): void {
    this.registerUserInfo = { registerUsrSysNo, registerToken };
  }

  /**
   * Get Register User Info
   */
  public getRegisterUserInfo(): RegisterUserInfo {
    return this.registerUserInfo;
  }

  /**
   * Set Resend Activation Email Flag
   * @param resendEmail - boolean
   */
  public setResendActivationEmailFlag$(resendEmail: boolean): void {
    this.resendActivationEmailFlag$.next(resendEmail);
  }

  /**
   * Get Resend Activation Email Flag
   */
  public getResendActivationEmailFlag$(): Observable<boolean> {
    return this.resendActivationEmailFlag$.asObservable();
  }

  /**
   * Map Access Register Status
   */
  public mapAccessRegisterStatus$(): Observable<AccessRegisterStatus> {
    return this.personalisationService.getPersonalData$().pipe(
      switchMap((personalData: PersonalisationPersonalData) => {
        return this.getTokenToAccessRegister$(personalData).pipe(
          tap(([userIdResponse]) => {
            logger.debug(userIdResponse);
            this.setRegisterUserInfo(
              userIdResponse?.body?.result?.usersysno,
              userIdResponse?.headers?.get('Authorization')
            );
          }),
          map(
            ([userIdResponse, expressNumberResponse]) => {
              return this.isAccessRegisterCorrect(
                userIdResponse,
                expressNumberResponse,
                personalData.identifiers?.expressNumber
              );
            },
            (error) => {
              logger.error('getRegisterToken API error', error);
              return AccessRegisterStatus.ERROR;
            }
          )
        );
      })
    );
  }

  /**
   * Checking conditions for Access Register Status
   * @param userIdResponse - User ID HttpResponse
   * @param expressNumberResponse - Express Number HttpResponse
   */
  private isAccessRegisterCorrect(
    userIdResponse: HttpResponse<any>,
    expressNumberResponse: HttpResponse<any>,
    expressNumber: string
  ): AccessRegisterStatus {
    if (
      !userIdResponse?.body?.result?.userStatus &&
      !expressNumberResponse?.body?.result?.status
    ) {
      // Set NOT_REGISTERED status for never registered users.
      return AccessRegisterStatus.NOT_REGISTERED;
    }
    if (
      !userIdResponse?.body?.result?.userStatus &&
      expressNumberResponse?.body?.result?.userStatus ===
        AccessRegisterStatus.ACTIVE
    ) {
      // Special case when user name is available, but user have an active account with a different id.
      return AccessRegisterStatus.ACTIVE;
    }
    if (
      !userIdResponse?.body?.result?.userStatus &&
      expressNumberResponse?.body?.result?.status
    ) {
      // Set ERROR status if user data is inconsistent.
      logger.error(
        `Bad user registration status, express number previously registered but not active: ${expressNumberResponse?.body?.result?.userStatus} - ${expressNumber}`
      );
      return AccessRegisterStatus.ERROR;
    }
    if (
      userIdResponse?.body?.result?.userStatus !==
        AccessRegisterStatus.ACTIVE &&
      userIdResponse?.body?.result?.userStatus !==
        AccessRegisterStatus.PARTIAL_REG
    ) {
      // Set ERROR status for not ACTIVE and PARTIAL_REG users.
      logger.error(
        `Bad user registration status: ${userIdResponse.body.result.userStatus} - ${expressNumber}`
      );
      return AccessRegisterStatus.ERROR;
    }
    return userIdResponse?.body?.result?.userStatus as AccessRegisterStatus;
  }
}
