import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Logger } from '@utils/logger';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { TranslateService } from '@shared/translate/translate.service';
import {
  CalcTypeStd,
  FundPerformanceAnnualizedPeriodEndData,
  FundPerformanceAnnualizedState,
  FundPerformanceAnnualizedStateConfig,
  FundPerformanceAnnualizedTableData,
  FundPerformanceDates,
  PerformanceView,
  ProductDetailConfiguration,
} from '@types';
import { FundPerformanceAnnualizedService } from '../services/fund-performance-annualized.service';
import dayjs from 'dayjs';
import {
  AnalyticsService,
  DuetDatePickerErrorMsg,
  ISOYearMonth,
  ToggleButton,
} from '@frk/eds-components';

import {
  ANALYTICS_DATE_EVENT,
  ANALYTICS_DATE_FORMAT,
  ANALYTICS_DETAILED_DATE_EVENT,
  DATE_FORMAT_YEAR_MONTH_STD,
  CUSTOM_DATE_CHANGE,
} from '@utils/app.constants';
import {
  getDatePickerErrorMsg,
  momentMonthYearFormat,
} from '@utils/il8n/moment-il8n';
import { S_SHARECLASS } from '@literature/const/literature-data-const';
import { PERIODS_MAPPER } from '../services/fund-performance.config';

const logger = Logger.getLogger('FundPerformanceAnnualizedComponent');

@Component({
  selector: 'ft-fund-performance-annualized',
  templateUrl: './fund-performance-annualized.component.html',
  styleUrls: ['./fund-performance-annualized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundPerformanceAnnualizedComponent
  extends BaseProductDetailComponent
  implements OnDestroy {
  dataSubscription: Subscription;
  isError = false;
  fundPerformanceData: FundPerformanceAnnualizedPeriodEndData;
  fundPerformanceConfig: FundPerformanceAnnualizedStateConfig;
  periodEndToggle: ToggleButton[];
  xAxisCategories: string[];
  customFundPerformanceData: FundPerformanceAnnualizedPeriodEndData;
  customFundPerformanceDates: FundPerformanceDates;
  selectedDate: string;
  selectedDateStd: ISOYearMonth;
  performanceStatusCaveat: string;
  heading: string;
  @Input() isdatePickerAnalyticsEnabled = false; // For Disabling Analytics for Date Selection

  public datePickerFormat: string = momentMonthYearFormat();
  public errorMsgObj: DuetDatePickerErrorMsg;

  // async extension to make it possible to use data in other components
  customFundPerformanceData$: Subject<FundPerformanceAnnualizedPeriodEndData> = new Subject();
  xAxisCategories$: Subject<string[]> = new Subject();
  tooltips: { [name: string]: string } = {};
  public callType = '';

  constructor(
    private fundPerformanceService: FundPerformanceAnnualizedService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private analytics: AnalyticsService
  ) {
    super();
    this.errorMsgObj = getDatePickerErrorMsg(this.translateService);
  }

  populate(
    componentConfig: FundPerformanceComponentConfig,
    brConfig: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    this.setTooltips();
    if (this.brConfig.fundId) {
      this.fundPerformanceService.setConfigurationName(
        componentConfig.configurationName
      );
      this.dataSubscription = this.fundPerformanceService.fundPerformanceState$.subscribe(
        (newState: FundPerformanceAnnualizedState) => {
          if (newState.isError) {
            this.isError = true;
          } else {
            this.mapState(newState);
          }
          this.changeDetector.detectChanges();
        }
      );
      this.fundPerformanceService.populate(brConfig);
    }
    this.changeDetector.detectChanges();
  }

  private setTooltips() {
    this.tooltips[
      'products.average-annual-total-returns-section'
    ] = this.translateService.tooltip(
      'products.average-annual-total-returns-section'
    );
  }

  /**
   * Called when state changes.
   * @param fundPerformanceState the new state returned by fund performance service
   */
  private mapState(fundPerformanceState: FundPerformanceAnnualizedState) {
    this.performanceStatusCaveat = fundPerformanceState.perfStatusCaveat;
    this.heading = fundPerformanceState.heading;
    if (fundPerformanceState.config.periodEnd === PerformanceView.QUARTERLY) {
      this.fundPerformanceData = fundPerformanceState.data.quarterEnd;
    } else if (
      fundPerformanceState.config.periodEnd !== PerformanceView.CUSTOM
    ) {
      this.fundPerformanceData = fundPerformanceState.data.monthEnd;
    }

    this.fundPerformanceConfig = fundPerformanceState.config;
    this.periodEndToggle = this.getPeriodEnds(fundPerformanceState);

    this.setCustomPerformanceData(fundPerformanceState);
  }

  private checkIfHistoricalDataPresent(
    historicalData: FundPerformanceAnnualizedPeriodEndData
  ): boolean {
    const historicalTableDataLength = historicalData?.tableData?.length;
    if (historicalTableDataLength === 1) {
      return (
        historicalData?.tableData?.filter(
          (item) => item !== undefined && item !== null
        )?.length > 0
      );
    }
    return historicalTableDataLength > 1;
  }

  private setCustomPerformanceData(
    fundPerformanceState: FundPerformanceAnnualizedState
  ) {
    if (fundPerformanceState.config.periodEnd === PerformanceView.QUARTERLY) {
      this.customFundPerformanceData = fundPerformanceState.data.quarterEnd;
      this.customFundPerformanceDates =
        fundPerformanceState.data.quarterEnd.performanceDates;
      this.selectedDate = this.customFundPerformanceDates.asOfDate;
      this.selectedDateStd = this.customFundPerformanceDates.asOfDateMonthStd;
    } else if (
      fundPerformanceState.config.periodEnd === PerformanceView.CUSTOM &&
      !this.checkIfHistoricalDataPresent(
        fundPerformanceState?.data?.historicalData
      )
    ) {
      // WDE-7270:[INC3814036] Product page data failing to load.
      logger.error(
        `[INC3814036] Failing to Load Historical Annualized Performance Data for fundId: ${this.brConfig.fundId}`
      );
    } else if (
      fundPerformanceState.config.periodEnd === PerformanceView.CUSTOM
    ) {
      this.customFundPerformanceData =
        fundPerformanceState.data?.historicalData;
    } else {
      this.customFundPerformanceData = fundPerformanceState.data.monthEnd;
      this.customFundPerformanceDates =
        fundPerformanceState.data.monthEnd.performanceDates;
      this.selectedDate = this.customFundPerformanceDates.asOfDate;
      this.selectedDateStd = this.customFundPerformanceDates.asOfDateMonthStd;
    }
    this.xAxisCategories = this.getXAxisCategories();
    // WDE-5739 Product - CPREIF Fund Page Update POP Suppression for S Class
    if (
      this.brConfig.fundId === '92083' &&
      this.brConfig.shareClassCode === S_SHARECLASS
    ) {
      const fundPerformanceData = this.customFundPerformanceData.tableData.filter(
        (e: FundPerformanceAnnualizedTableData) => {
          if (
            !(e.feeStructure !== S_SHARECLASS) &&
            !(e.calcTypeStd === CalcTypeStd.POP)
          ) {
            return e;
          }
        }
      );
      this.customFundPerformanceData.tableData = fundPerformanceData;
    }

    // NGC-5378 - now we can reuse this result in other components like search
    this.customFundPerformanceData$.next(this.customFundPerformanceData);
    this.xAxisCategories$.next(this.xAxisCategories);
  }

  private getPeriodEnds(
    fundPerformanceState: FundPerformanceAnnualizedState
  ): ToggleButton[] {
    return fundPerformanceState.config.periodEnds
      .map((periodEnd) => {
        const period = PERIODS_MAPPER[periodEnd];
        const hasData = fundPerformanceState.data[period].tableData.some(
          (data) => data !== undefined
        );
        if (hasData) {
          return {
            text: this.translateService.instant(
              `products.${periodEnd}`,
              this.brConfig.fundId
            ),
            active: periodEnd === fundPerformanceState.config.periodEnd,
            value: periodEnd,
          };
        }
      })
      .filter((period: ToggleButton) => period !== undefined);
  }

  /**
   * Update the performance view as per type selected.
   */
  onPeriodEndUpdate(selectedToggleOption: ToggleButton) {
    this.callType = '';
    this.fundPerformanceService.setPeriodEnd(selectedToggleOption.value);
  }

  getXAxisCategories(): string[] {
    return this.customFundPerformanceData?.graphLabels
      ? this.customFundPerformanceData.graphLabels.map((label) =>
          this.translateService.instant(label)
        )
      : [];
  }

  onHistoricalDateSelection(selectedMonthDate: ISOYearMonth) {
    const date = dayjs(selectedMonthDate, 'YYYY-MM');
    this.selectedDate = date.format();
    this.selectedDateStd = date.format(
      DATE_FORMAT_YEAR_MONTH_STD
    ) as ISOYearMonth;
    if (this.callType === CUSTOM_DATE_CHANGE) {
      this.fundPerformanceService.populateHistoricalData(
        this.selectedDate,
        this.callType
      );
    }

    if (this.isdatePickerAnalyticsEnabled) {
      this.isdatePickerAnalyticsEnabled = false;
      this.analytics.trackEvent({
        event: ANALYTICS_DATE_EVENT,
        detailed_event: ANALYTICS_DETAILED_DATE_EVENT,
        action: 'Start Date',
        label: `${date.format(ANALYTICS_DATE_FORMAT)}`,
        event_data: {
          date_type: ANALYTICS_DATE_FORMAT,
          date_value: `${date.format(ANALYTICS_DATE_FORMAT)}`,
        },
      });
    }
  }

  // enable date picker analytics once user change the date
  onOpenMonthPicker(value: string) {
    if (value === 'open') {
      this.isdatePickerAnalyticsEnabled = true;
      this.callType = CUSTOM_DATE_CHANGE;
    }
  }

  ngOnDestroy() {
    // clean up subscription to avoid memory leaks
    this.dataSubscription?.unsubscribe();
  }
}
