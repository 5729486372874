import { Injectable, OnDestroy } from '@angular/core';
import { StickyTab } from '@frk/eds-components';
import { Logger } from '@utils/logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WindowScrollService } from './window-scroll.service';

/**
 * Logger
 */
const logger = Logger.getLogger('TabsStickyService');

@Injectable({
  providedIn: 'root',
})
export class TabsStickyService implements OnDestroy {
  // in addition to unsubscribing when the service is destroyed, this unsubscribes (i.e. resets) when the tabs list is updated
  private currentSectionUnsubscribe$: Subject<void> = new Subject<void>();
  private tabIds: string[] = [];

  /**
   * Active Tab Id
   * This will emit when active tab changes due to scrolling
   */
  private activeTabId$: BehaviorSubject<string> = new BehaviorSubject('');

  private getTabsId$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(private scrollService: WindowScrollService) {}

  ngOnDestroy(): void {
    this.currentSectionUnsubscribe$.next();
    this.currentSectionUnsubscribe$.complete();
  }

  /**
   * Get observable of activetabId
   */
  public getActiveTabId$(): Observable<string> {
    return this.activeTabId$.asObservable();
  }

  /**
   * Call this when a sticky tab is clicked
   * It doesn't do the actually scrolling, but temporarily supresses scroll spy and sets the active tab
   * @param id - Id of active tab
   */
  public tabClicked(tab: StickyTab): void {
    logger.debug('tabClicked()', tab);
    // this stops active tab jumping when click causes scrolling
    this.scrollService.suppressScrollSpy();
  }

  /**
   * Set Sticky Tab List
   * @param stickyTabs object
   */
  public setStickyTabs(stickyTabs: StickyTab[]): void {
    logger.debug('setStickyTabs()', stickyTabs);
    this.tabIds = stickyTabs?.map((tab: StickyTab): string => tab.id);

    // reset the getCurrentSection$() subscription every time tab list changes
    this.setScrollListener();
    this.getTabsId$.next(this.tabIds);
  }

  /**
   * Get observable of Tab lists
   */
  public getCurrentTabs$(): Observable<string[]> {
    return this.getTabsId$.asObservable();
  }

  /**
   * Listen to see when a StickyTab id comes into view,
   */
  private setScrollListener() {
    // reset previous subscription now tabs have changed
    this.currentSectionUnsubscribe$.next();

    // set up ScrollSpy functionality
    this.scrollService
      .getCurrentSection$(this.tabIds)
      .pipe(takeUntil(this.currentSectionUnsubscribe$))
      .subscribe((id: string): void => {
        // Get current section user is viewing
        logger.debug('getCurrentSection$()', id);
        // Update the current tab id
        this.activeTabId$.next(id);
      });
  }
}
