import { ShareClass } from '@models';
import { FundId, LiteratureDocType, ShareClassCode } from '@types';
import { Nominal } from 'simplytyped';

export interface LiteratureDocument {
  docId?: string | null;
  rowId?: string | null;
  frkOneTISNumber?: FundId[] | null; // TODO: rename as fundId for consistency inside this repo
  href?: string | null;
  dctermsTitle?: string | null;
  dctermsDescription?: string | null;
  dctermsAudience?: string[] | null;
  dctermsType?: LiteratureDocType | null;
  translatedDctermsType?: string | null;
  publicationDate?: string | null; // format "YYYY-MM-DD" TODO: refactor as ISODate type
  isNew?: boolean; // indicates if publicationDate is less than 30 days old
  frkShareClassCode?: ShareClassCode[] | null;
  literatureHref?: string | null;
  frkReferenceDate?: string | null;
  frkReferenceYear?: string | null;
  shareclass?: ShareClass;
  fileExtension?: string | null;
  frkFundGroupCode?: string | null;
  identifiers?: KeyIdentifiers;
  cmsExternalLink?: string | null;
  nextUpdateDate?: string | null;
  clientUse?: string | null;
  topic?: string[] | null;
  subTopic?: string | null;
  downloadUrl?: string | null;
  fundName?: string | null;
  availableInCart?: boolean;
  reOrderQty?: number | null;
  isSiteIntl?: boolean;
  imageUrl?: string;
  thumbnailPath?: string;
  finraLink?: string;
  pdfDownloadLink?: string;
  assetCategory?: string;
  loginRequired?: boolean;
  isOrdItself?: string;
  isInvestor?: boolean;
  mandatoryMaterial?: string[];
  quantity?: number;
  literatureCode?: string;
  fileSize?: string;
  description?: string;
  isInv?: string;
  hardCopyNotAvailable?: boolean;
  keywords?: string[];
  litDealerUse?: boolean;
  isMandatoryMaterial?: boolean;
  mandatoryMaterialDependentMsg?: string;
  mandatoryMaterialPrimary?: string;
  blueSkyStates?: string[];
  litPathPrevious?: string;
  isWidenData?: boolean;
  customFilePath?: string;
  orderDate?: string;
  isSelected?: boolean;
  dctermsLanguage?: string[];
  moreLink?: string;
  showShareClass?: boolean;
  languageFilterValues?: string[];
  pdfNumberingAppID?: string | null;
  isDuplicateDCTermsType?: boolean;
  allShareClass?: ShareClass[];
  usePreviewLink?: boolean;
  multipleProductsForSearch?: FundSearch;
  isHistoryData?: boolean;
  fundNameGenerated?: string | null;
  externalId?: string;
  documentSourceSystem?: string;
  availableForLoggedInUser?: string;
  literatureDetailUrl?: string;
  suppressIndexing?: boolean;
  fileLink?: string;
  fileUploadDate?: string;
  approvedFirm?: string[];
  firmSpecific?: string[];
  approvedFirms?: string[];
  isPublicDoc?: string;
  specificTargetSystems?: string[];
}

export type DctermType = Nominal<string, 'DctermType'>;

export interface FundSearch {
  fundIds?: FundId[];
  fundNames?: string[];
  identifiers?: KeyIdentifiers;
}
export enum Audience {
  Public = 'investor',
  public = 'investor',
  Advisor = 'advisor',
  INSTITUTIONAL = 'institutional',
}

export enum NoYesFlag {
  YES = 'Yes',
  NO = 'No',
}

export enum FileExtension {
  pdf = 'pdf',
  doc = 'document',
  docx = 'document',
  word = 'document',
  powerpoint = 'ppt',
  pptx = 'ppt',
  oft = 'mail',
  outlook = 'mail',
  xls = 'xls',
  xlsx = 'xls',
  xlsm = 'xls',
}

export enum DocumentMatchCondition {
  FUND_SHARE_CLASS = 'FUND_SHARE_CLASS',
  FUND_ID = 'FUND_ID',
  FUND_UMBRELLA = 'FUND_UMBRELLA',
  UNASSOCIATED = 'UNASSOCIATED',
}

export enum SpecificTargetSystemsValues {
  PERSONALIZED_RESOURCE_CENTER = 'PERSONALIZED-RESOURCE-CENTER',
}

export interface KeyIdentifiers {
  isin?: string[];
  sedol?: string[];
  bloomberg?: string[];
  taid?: string[];
  cusip?: string[];
  reuterSystem?: string[];
  shareClassFeeStructure?: string[];
  wkn?: string[];
  vln?: string[];
  mexid?: string[];
  rfi?: string[];
  apir?: string[];
  arsn?: string[];
  adm?: string[];
  dsc?: string[];
  ticker?: string[];
  iress?: string[];
  iressinavcode?: string[];
  anbimacode?: string[];
  intradaynav?: string[];
  fundbmticker?: string[];
  shareClassCurrency?: string[];
  distributionIndicator?: string[];
  dividendFrequency?: string[];
}

export interface DocumentList {
  name?: string | null;
  docId?: string | null;
  documentUrl?: string | null;
  downloadUrl?: string | null;
  client?: string;
  itemCardView?: ItemCardView;
  cardView?: ItemCardView[];
  reOrderQty?: number;
  quantity?: number;
  isOrdItself?: string;
  isInvestor?: string;
  mandatoryMaterial?: string[];
  hardCopyNotAvailable?: boolean;
  litDealerUse?: boolean;
  isMandatoryMaterial?: boolean;
  mandatoryMaterialDependentMsg?: string;
  mandatoryMaterialPrimary?: string;
  imageUrl?: string;
  fileExtension?: string;
}
export interface StorageItems {
  docId?: string | null;
  quantity?: number;
}

export interface CookieResponse {
  items: string[];
  isError?: boolean;
}
export interface ItemCardView {
  label?: string | null;
  val?: string | null;
  size?: string | null;
}

// see https://stackoverflow.com/a/51507473 for extending and overriding prop type
export interface ItemDetail extends LiteratureDocument {
  loginRequired?: boolean;
  isRestrictedActor?: boolean;
  // publicationDate?: string; // this is now a descriptive string instead of YYYY-MM-DD format date
}
